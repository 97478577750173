import { useTranslation } from "react-i18next"

const Logo = ({ dark }) => {
  const { t } = useTranslation()

  return (
    <a
      className={`logo ${dark ? "logo--accent-dark" : "logo--accent-white"} header__logo`}
      href="/"
    >
      <span className="logo__title">
        <span className="logo__title-text">Work</span>
        <span className="logo__title-text-accent">Bet</span>
      </span>
      {/* <span className="logo__subtitle">{t("notification.guarantee")}</span> */}
    </a>
  )
}

export default Logo
