import { useTranslation } from "react-i18next"
import imgPayment from "../../../../assets/payments/payments.png"

const TariffsFaqPicture = () => {
  const { t } = useTranslation()

  return (
    <picture className="tariffs-faq-section__payments">
      <img src={imgPayment} alt={t("buttons.payment-types")} />
    </picture>
  )
}

export default TariffsFaqPicture
