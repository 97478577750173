import { IconAction } from "../ui/icons/Icons"
import { Toast } from "primereact/toast"
import { useRef } from "react"
import { useTranslation } from "react-i18next"

const InputCardBetName = ({ children, url, copy }) => {
  const { t } = useTranslation()
  const toast = useRef(null)

  function handlerCopyUrl() {
    try {
      navigator.clipboard.writeText(url)
    } catch (e) {
      console.error(e)
    }

    toast.current.show({
      severity: "info",
      summary: t("buttons.information"),
      detail: t("buttons.link-copy"),
      life: 1000
    })
  }

  const tagA = (
    <a
      className="input-el__link normal-a"
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  )
  const btnCopy = (
    <button
      className="js-event-copy-name btn-copy input-el__btn-copy tooltip-show"
      data-pr-tooltip={t("buttons.copy")}
      data-pr-position="right"
      onClick={handlerCopyUrl}
      type="button"
    >
      <IconAction name="copy" />
    </button>
  )
  return (
    <>
      <div className="input-el input-el--is-readonly event-card__bo-name">
        {url ? tagA : children}
        {copy ? btnCopy : ""}
      </div>
      <Toast ref={toast} position="top-center" />
    </>
  )
}

export default InputCardBetName
