export const ForksEn = () => {
  return (
    <>
      <h1 className="main__title">Вилки</h1>
      <h2>Как это работает</h2>
      <p>
        Созданный нами сервис позволяет ставить без риска: вы будете получать
        доход независимо от исхода спортивного события! Как это возможно?
        Благодаря использованию букмекерских вилок*, для вычисления которых
        применяются элементарные математические формулы, при помощи которых
        заработок на вилках становится реальным.
      </p>
      <p>
        * Букмекерские вилки – это математически просчитанные арбитражные
        ситуации, которые образуются из-за разницы в коэффициентах букмекеров и
        позволяют делать ставки на все противоположные исходы состязания в
        разных БК таким образом, чтобы вы получали прибыль при любом исходе
        матча!
      </p>
      <h2>Почему образуется букмекерская вилка</h2>
      <ol>
        <li>
          Высокая конкуренция между букмекерскими конторами, в результате
          которой нередко выставляются завышенные коэффициенты (КФ).
        </li>
        <li>
          Букмекеры не успевают уследить за изменениями в линиях конкурирующих
          БК, из-за чего и появляется разница между их коэффициентами.
        </li>
        <li>
          Ошибки самих букмекеров, которые приводят к неправильным расчетам
          коэффициентов.
        </li>
      </ol>
      <p>
        Мы сканируем линии букмекерских контор, затем рассчитываем вилки при
        помощи математических формул и сообщаем вам — КУДА и СКОЛЬКО необходимо
        поставить, чтобы при любом исходе матча получить гарантированный доход.
      </p>
      <p>
        Вы сможете получать от 5% до 15-20% прибыли от общей суммы инвестиций. А
        поскольку ваш игровой банк будет постоянно увеличиваться за счет
        выигранных средств, ваш капитал будет расти в геометрической прогрессии.
      </p>
      <h2>Виды букмекерских вилок</h2>
      <p>
        Существует разделение букмекерских вилок по количеству исходов
        (двухисходные) и по времени начала события (прематч, лайв).
      </p>
      <p>
        Прематч вилки появляются из-за разницы в коэффициентах букмекерских
        контор в еще не начавшихся событиях, а лайв — в событиях, проходящих в
        настоящее время. Как и прематч, так и лайв имеют свои преимущества и
        недостатки.
      </p>
      <p>
        Так, среди основных преимуществ прематч вилок можно отметить медленную
        смену коэффициентов и простоту в работе, а среди недостатков — менее
        ощутимую прибыль и медленный оборот игрового банка.
      </p>
      <p>
        Лайв же вилки привлекают игроков более высокой доходностью и меньшими
        рисками, но не подходят новичкам из-за быстрой смены коэффициентов и,
        соответственно, короткого “срока жизни” вилки.
      </p>
      <h2>Пример букмекерской вилки</h2>
      <p>
        Есть 2 баскетбольные команды: Лестер Уорриорз и Вестминстер Уорриорз. На
        выбор у нас есть большое количество букмекерских контор, в каждой из
        которых различные коэффициенты на победу Команды1 или Команды2. Наш
        сервис при помощи специально разработанной технологии сканирования и
        расчета вилок проделывает следующие шаги:
      </p>
      <ol>
        <li>
          Сканирует все коэффициенты на баскетбольный матч Лестер Уорриорз —
          Вестминстер Уорриорз в букмекерских конторах.
        </li>
        <li>
          Определяет наилучшие коэффициенты среди всех возможных и при помощи
          элементарной математики вычисляет вилки.
        </li>
        <li>
          Отбирает самые выгодные букмекерские вилки и сообщает вам, куда и
          сколько денег необходимо поставить для получения гарантированного
          дохода.
        </li>
      </ol>
      <p>
        Предположим, просканировав букмекерские конторы на баскетбольный матч
        Лестер Уорриорз — Вестминстер Уорриорз, сервис отобрал БК с такими
        параметрами:
      </p>
      <table className="public-table">
        <tbody>
          <tr>
            <td>Leicester Warriors</td>
            <td>Bookmaker</td>
            <td>Westminster Wariors</td>
          </tr>
          <tr>
            <td>1.25</td>
            <td>B1</td>
            <td>3.9</td>
          </tr>
          <tr>
            <td>1.43</td>
            <td>B2</td>
            <td>2.85</td>
          </tr>
        </tbody>
      </table>
      <p>
        Определяем сумму инверсии (L) всех результатов в букмекерских конторах:
      </p>
      <b>
        B1: L = 1/1.25 + 1/3.9 = 1.056
        <br />
        B2: L = 1/1.43 + 1/2.85 = 1.051
      </b>
      <p>
        Соответственно, при любых исходах матча БК1 рассчитывает получить 5,6%
        прибыли, а БК2 5,1% прибыли. Наша задача — определить КФ в разных
        букмекерских конторах таким образом, чтобы сумма инверсии
        противоположных результатов была меньше 1. Только в этом случае возможна
        букмекерская вилка:
      </p>
      <p>
        1. Подсчитаем сумму инверсии результатов (L) для вышеуказанных БК1 и
        БК2. Возьмем ситуацию, что в БК1 делается ставка на победу Вестминстер
        Уорриорз, а в БК2 — на победу Лестер Уорриорз. В итоге получаем:
      </p>
      <b>L = 1/3.9 + 1/1.43 = 0.9557</b>
      <p>
        Как видите, сумма инверсии противоположных ставок меньше 1, значит, эти
        две букмекерские конторы с соответствующими коэффициентами подходят нам
        для дальнейших расчетов.
      </p>
      <p>
        2. Рассчитаем, сколько денег и куда мы должны поставить — при условии,
        что у нас на руках имеется 1000 долларов для ставок на букмекерские
        вилки. Сумма ставки (V1) для БК1 и сумма ставки (V2) для БК2 получаются
        следующими:
      </p>
      <b>
        V1 = 1000/(0.9557*3.9) = $268.30
        <br />
        V2 = 1000/(0.9557*1.43) = $731.70
      </b>
      <p>
        Следовательно, для получения гарантированного дохода с помощью данной
        букмекерской вилки мы должны поставить $268,30 на победу Вестминстер
        Уорриорз в БК1 и $731,70 на победу Лестер Уорриорз в БК2.
      </p>
      <p>
        3. Теперь рассчитаем размер гарантированной прибыли (Р) от общей суммы
        ставок, поставленных в БК для обоих случаев.
      </p>
      <p>
        Если победит Вестминстер Уорриорз:{" "}
        <b>P = 268.30 x 3.9 - (268.30 + 731.70) = $46.33</b>
        <br />
        Если победит Лестер Уорриорз:{" "}
        <b>P = 731.70 x 1.43 - (268.30 + 731.70) = $46.33</b>
      </p>
      <div className="color-pink">
        <p>
          Из расчетов видно, что при любом исходе матча при общей сумме ставок
          1000 долларов. мы гарантированно получаем $1046,33, из которых чистой
          прибыли $46,33 или 4,42% от суммы ставок.
        </p>
        <p>
          Поздравляем! Букмекерская вилка помогла вам заработать первые деньги.
          А теперь представьте, что будет, если проделывать такие операции много
          раз в день и с суммами, которые в разы больше вышеуказанных? Правильно
          — вы сможете зарабатывать отличные деньги!
        </p>
      </div>
    </>
  )
}
