import Logo from "../../ui/logo/Logo"
import FooterMenu from "./menu/FooterMenu"
import ButtonWriteToUs from "../../buttons/ButtonWriteToUsFooter"
import FooterSocial from "./social/FooterSocial"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const Footer = () => {
  const { t } = useTranslation()

  return (
    <footer className="footer">
      <div className="container footer__container">
        <div className="footer__inner">
          <div className="footer__col footer__col--logo">
            <Logo dark={true} />
          </div>
          <div className="footer__col footer__col--nav">
            <FooterMenu />
          </div>
          <div className="footer__col footer__col--contacts">
            <ButtonWriteToUs>{t("footer.write-us")}</ButtonWriteToUs>
            <FooterSocial />
          </div>
        </div>
        <div className="footer__copyright">
          <p>
            <Link to="/privacy">{t("footer.privacy")}</Link> {t("sign-up.and")}{" "}
            <Link to="/terms">{t("footer.terms")}</Link>
            <br />
            {t("footer.workbet")}
            <br />
            {t("footer.info")}
            <br />
            {t("footer.rights")}
          </p>
          <p>Workbet © 2022 - {new Date().getFullYear()}</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
