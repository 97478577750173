import Main from "../../../components/layout/main/Main"
import { useTranslation } from "react-i18next"

export const Promotion = () => {
  const { t } = useTranslation()

  return (
    <Main>
      <h1 className="main__title">{t("pages.promotion-title")}</h1>
      <p>{t("pages.promotion-main")}</p>
    </Main>
  )
}
