export const FaqRu = () => {
  return (
    <>
      <h1 className="main__title">FAQ (часто задаваемые вопросы)</h1>
      <h2>Кто обычно пользуется вилочными сканерами?</h2>
      <p>
        Такие программы, как правило, используют бетторы, которые хотят серьезно
        зарабатывать на ставках.
      </p>
      <h2>Где выгоднее использовать сканеры: в прематче или лайве?</h2>
      <p>
        Каждый сервис имеет свои преимущества и недостатки. Лайв сканеры
        приносят большую прибыль, но арбитражные ситуации в прематче «живут»
        дольше.
      </p>
      <h2>
        Вилочный сканер дает сведенья о том, сколько нужно поставить на разные
        исходы, чтобы в любом случае выиграть?
      </h2>
      <p>
        Нет, сервис только ищет и показывает возможные вилки. Беттор может
        сделать нужные расчеты с помощью арбитражного калькулятора.
      </p>
      <h2>Как работают вилочные сервисы</h2>
      <p>
        Вилочные программы анализируют линии разных букмекерских контор,
        сопоставляют коэффициенты, а затем дают вилочнику полную сводку по
        доступным арбитражным ситуациям. Так беттор получает преимущество над
        букмекерами.
      </p>
      <p>
        Программы работают с высокой скоростью и обновляют информацию
        оперативно, так что можно «ловить» арбитражные ситуации, которые
        появляются всего лишь на пару минут.
      </p>
      <h2>Выделяют следующие типы вилочных сканеров:</h2>
      <ul>
        <li>
          <b>Прематч</b>. Такие сервисы специализируются на поиске подходящих
          арбитражных ситуаций среди событий, которые еще не стартовали. Среди
          главных недостатков такого подхода можно выделить существенно меньший
          выбор исходов в прематче.
        </li>
        <li>
          <b>Live</b>. Служат для поиска арбитражных ситуаций в лайве. Основный
          плюс в том, что на пари в лайве вилочника сложнее обнаружить, так как
          контора может не успевать проверять всех конкурентов. К недостаткам
          относится то, что букмекерские конторы увеличивают маржу в лайве.
        </li>
        <li>
          <b>Универсал</b>. Такие сканеры работают в прематче и лайве, что
          позволяет находить больше арбитражных ситуаций. Однако стоимость
          универсальных программ выше, чем других вилочных сервисов.
        </li>
      </ul>
      <p>
        В зависимости от доступного функционала и качества поиска каждый сервис
        устанавливает свою цену подписки для пользователей.
      </p>
      <h2>Что такое спортивный арбитраж?</h2>
      <p>
        Спортивный арбитраж (букмекерская вилка) — это такая ситуация, когда
        разница коэффициентов в двух или более конторах позволяет сделать по
        ставке на каждый взаимоисключающий исход у разных букмекеров и остаться
        в прибыли при любом результате. Такая ситуация может возникнуть из-за
        конкуренции между конторами в размере коэффициентов, различной оценки
        шансов, ошибки в выставлении коэффициентов.
      </p>
      <div className="color-pink">
        <p className="text-align--center font-size--18">
          Итак, как же найти вилку и правильно сделать ставки?
          <br />
          Тут на помощь приходит наша экосистема Workbet!
        </p>
      </div>
    </>
  )
}
