import styles from "../autorization.module.css"
import { Link } from "react-router-dom"
import { useFormik } from "formik"
import { classNames } from "primereact/utils"
import { useApp } from "../../../hook/useApp"
import { observer } from "mobx-react-lite"
import { useTranslation } from "react-i18next"
import { Captcha } from "primereact/captcha"

const SignIn = observer(({ visible, handlerClose }) => {
  const { store } = useApp()
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      captcha: ""
    },
    validate: (data) => {
      let errors = {}

      if (!data.email) errors.email = t("sign-in.error-email")
      if (!data.password) errors.password = t("sign-in.error-password")
      if (!data.captcha) errors.captcha = t('sign-in.error-captcha')

      return errors
    },
    onSubmit: async (data) => {
      store.Auth.setAuthError("")
      await store.Auth.login(data)
      store.SportEvent.getStatistics()
    }
  })

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name])

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    )
  }

  if (!visible) return ""

  return (
    <div className={styles["modal-window"]}>
      <div className={styles["modal-window__close"]} onClick={handlerClose} />
      <div className={styles["modal-window__title"]}>{t("sign-in.title")}</div>
      <div className={styles["modal-window__sub-title"]}>
        {t("sign-in.subtitle")}{" "}
        <b>
          <Link to="/signup">{t("sign-in.signup")}</Link>
        </b>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles["modal-window_row"]}>
          <input
            type="text"
            placeholder="E-mail"
            className={classNames({ "p-error": isFormFieldValid("email") })}
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
          />
          {getFormErrorMessage("email")}
        </div>
        <div className={styles["modal-window_row"]}>
          <input
            type="password"
            placeholder={t("sign-in.password")}
            className={classNames({ "p-error": isFormFieldValid("password") })}
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
          />
          {getFormErrorMessage("password")}
        </div>
        <div
          className={`${styles["modal-window_row"]} ${styles["modal-window_row--right"]} margin-bottom--25`}
        >
          <b>
            <Link to="/forgot-password">{t("sign-in.forgot-password")}</Link>
          </b>
        </div>
        <div
          className={`${styles["modal-window_row"]} ${styles["modal-window_row--center"]}`}
        >
          <Captcha
            siteKey={process.env.REACT_APP_CAPTCHA_KEY}
            onResponse={(response) => formik.setFieldValue("captcha", response)}
            onExpire={() => formik.setFieldValue("captcha", "")}
            language="ru"
          />
          {getFormErrorMessage("captcha")}
        </div>
        <div
          className={`${styles["modal-window_row"]} ${styles["modal-window_row--center"]}`}
        >
          <div className={styles["modal-window_col"]}>
            <p className="p-error">{store.Auth?.authError || ""}</p>
            <button type="submit" className="app-button">
              {t("sign-in.submit")}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
})

export { SignIn }
