import Main from "../../components/layout/main/Main"

import { useApp } from "../../hook/useApp"
import { useTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"

export const AccountActivate = observer(() => {
  const { store } = useApp()
  const { t } = useTranslation()
  const { id } = useParams()

  useEffect(() => {
    store.Auth.activateAccount(id)
  }, [])

  return (
    <Main>
      <h1>{t("account.activation")}</h1>
      <p>{store.Auth.statusActivate}</p>
      <Link to="/signin">{t("account.main")}</Link>
    </Main>
  )
})
