import { useTranslation } from "react-i18next"

import imgSports from "../../../../assets/balls-sports.png"
import imgDiagram from "../../../../assets/diagram.png"
import imgAdvantage from "../../../../assets/advantage.png"

export const TariffsFaqInfo = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className="tariffs-faq-section__title">{t("tariffs.faq-title")}</div>
      <div className="tariffs-faq-section__info">
        <div className="tariffs-faq-section__info-item">
          <div className="tariffs-faq-section__info-icon">
            <img src={imgSports} alt={t("tariffs.faq-sports")} />
          </div>
          <p>{t("tariffs.faq-sports")}</p>
        </div>
        <div className="tariffs-faq-section__info-item">
          <div className="tariffs-faq-section__info-icon">
            <img src={imgDiagram} alt={t("tariffs.faq-profit")} />
          </div>
          <p>{t("tariffs.faq-profit")}</p>
        </div>
        <div className="tariffs-faq-section__info-item">
          <div className="tariffs-faq-section__info-icon">
            <img src={imgAdvantage} alt={t("tariffs.faq-legal")} />
          </div>
          <p>{t("tariffs.faq-legal")}</p>
        </div>
      </div>
    </>
  )
}
