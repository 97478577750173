import { useApp } from "../hook/useApp"

import { observer } from "mobx-react-lite"

import { Navigate, Outlet } from "react-router-dom"
import { useEffect } from "react"

export const AuthProvider = observer(() => {
  const { store } = useApp()

  useEffect(() => {
    store.Auth.getInfoForUser()
  }, [])

  const isAuth = store.Auth.authUser

  if (!isAuth) return <Navigate to="/" />

  return <Outlet />
})
