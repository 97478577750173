import Main from "../../../components/layout/main/Main"
import PaymentsImg from "../../../assets/public-pages/payments.webp"
import { useTranslation } from "react-i18next"

export const Payments = () => {
  const { t } = useTranslation()

  return (
    <Main>
      <h1 className="main__title">{t("pages.payments-title")}</h1>
      <p>{t("pages.payments-1")}</p>
      <p>{t("pages.payments-2")}</p>
      <img src={PaymentsImg} alt="" />
    </Main>
  )
}
