import { IconAction } from "../ui/icons/Icons"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { formatDate, formatTime, unixDateToDate } from "../../helpers"

const EventTitle = observer(({ store }) => {
  const { t } = useTranslation()
  const { eventDateTime, eventName, nameTournament } =
    store.SportEvent.eventMainData

  const teams = eventName || t("buttons.unknown")
  const tournament = nameTournament || t("buttons.unknown")
  const dateTime = eventDateTime ? unixDateToDate(eventDateTime) : false
  const date = dateTime ? formatDate(dateTime) : t("buttons.unknown")
  const time = dateTime ? formatTime(dateTime) : t("buttons.unknown")

  return (
    <div className="container event__container">
      <div className="page-title">
        <h1>{t("buttons.all-forks")}</h1>
      </div>
      <div className="event-title">
        <div className="event-title-col">
          <div className="event-title__item">
            <Link to="/">
              <div className="event-back">
                <IconAction name="event-back" className="icon-event-back" />
              </div>
            </Link>
          </div>
        </div>
        <div className="event-title-col">
          <div className="event-title__item">
            <h3>{date}</h3>
          </div>
          <div className="event-title__item">
            <h3 className="text-align--center">{time}</h3>
          </div>
        </div>
        <div className="event-title-col">
          <div className="event-title__item">
            <h3>{teams}</h3>
          </div>
          <div className="event-title__item">{tournament}</div>
        </div>
      </div>
    </div>
  )
})

export default EventTitle
