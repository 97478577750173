import { useEffect, useState } from "react"
import Main from "../../../components/layout/main/Main"
import { StartWinningRu } from "./StartWinning/StartWinningRu"
import { StartWinningEn } from "./StartWinning/StartWinningEn"

const Markup = (language) => {
  switch (language) {
    case "ru":
      return <StartWinningRu />
    case "en":
      return <StartWinningEn />
  }
}

export const StartWinning = () => {
  const [language, setLanguage] = useState(null)

  useEffect(() => {
    let language = localStorage.getItem("language")
    setLanguage(language || "ru")
  }, [])

  return <Main>{Markup(language)}</Main>
}
