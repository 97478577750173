import SpriteIcon from "../../../assets/sprites/icons.svg"
import SpriteSocial from "../../../assets/sprites/socials.svg"
import SpriteSport from "../../../assets/sprites/sports.svg"

export const IconAction = ({ name, className }) => {
  return (
    <svg className={className ? className : "icon event-card__icon"}>
      <use xlinkHref={`${SpriteIcon}#${name}`}></use>
    </svg>
  )
}
export const IconSocial = ({ name }) => {
  return (
    <svg className="icon contacts-media__icon">
      <use xlinkHref={`${SpriteSocial}#${name}`}></use>
    </svg>
  )
}
export const IconSport = ({ visible = true, name }) => {
  if (!visible) return ""
  return (
    <svg className="icon event-card__icon">
      <use xlinkHref={`${SpriteSport}#${name}`}></use>
    </svg>
  )
}
