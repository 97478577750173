export const TaxRequestsRu = () => {
  return (
    <>
      <h1 className="main__title">НАЛОГОВЫЕ ЗАПРОСЫ</h1>
      <h2>Вопрос</h2>
      <p>Кто и в каком случае должен платить налог?</p>
      <h2>Кратко</h2>
      <p>
        Не платим налог на выигрыш от 15000 рублей в легальных БК. За всё
        остальное платим.
      </p>
      <h2>Подробно</h2>
      <p>
        Если букмекер — резидент (легальный), то с каждого выигрыша НДФЛ
        рассчитывает и уплачивает именно он как налоговый агент (214.7 НК РФ).
        Но согласно новому федеральному закону это касается только выигрышей
        суммой от 15 тыс рублей. То есть при выигрыше более 15 тыс. рублей
        букмекер уменьшает сумму выигрыша на сумму ставки и удерживает налог с
        полученной разницы в размере 13% для налоговых резидентов РФ или по
        ставке 30% для нерезидентов РФ.
      </p>
      <p>
        Если выигрыш составил менее 15 тыс. рублей, то букмекер выплатит его
        клиенту полностью, и перестает быть его налоговым агентом. Игрок должен
        по итогам года самостоятельно отчитаться в налоговой.
      </p>
      <p>
        Если букмекер — нерезидент (офшорный), то игрок обязан вести учет и
        расчет НДФЛ. Налог вы уплачиваете сами и лично подаете налоговую
        декларацию в установленный законом срок.
      </p>
    </>
  )
}
