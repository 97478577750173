import { useEffect, useState } from "react"
import Main from "../../../components/layout/main/Main"
import { IdentificationRu } from "./Identification/IdentificationRu"
import { IdentificationEn } from "./Identification/IdentificationEn"

const Markup = (language) => {
  switch (language) {
    case "ru":
      return <IdentificationRu />
    case "en":
      return <IdentificationEn />
  }
}

export const Identification = () => {
  const [language, setLanguage] = useState(null)

  useEffect(() => {
    let language = localStorage.getItem("language")
    setLanguage(language || "ru")
  }, [])

  return <Main>{Markup(language)}</Main>
}
