import { useEffect, useState } from "react"
import Main from "../../../components/layout/main/Main"
import { LegalRequestsRu } from "./LegalRequests/LegalRequestsRu"
import { LegalRequestsEn } from "./LegalRequests/LegalRequestsEn"

const Markup = (language) => {
  switch (language) {
    case "ru":
      return <LegalRequestsRu />
    case "en":
      return <LegalRequestsEn />
  }
}

export const LegalRequests = () => {
  const [language, setLanguage] = useState(null)

  useEffect(() => {
    let language = localStorage.getItem("language")
    setLanguage(language || "ru")
  }, [])

  return <Main>{Markup(language)}</Main>
}
