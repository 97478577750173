import { observer } from "mobx-react-lite"
import { useApp } from "../../../hook/useApp"
import { useTranslation } from "react-i18next"

const LogoutMessage = observer(() => {
  const { store } = useApp()
  const { t } = useTranslation()

  const logoutMessage = store.UIInterface.logoutMessage

  function closelogoutMessage() {
    store.UIInterface.removeLogoutMessage()
  }

  return (
    <div className="logout-message-block">
      <h3>{t("footer.error")}</h3>
      {logoutMessage}
      <div
        className="logout-message-block__close-button"
        onClick={closelogoutMessage}
      ></div>
    </div>
  )
})

export default LogoutMessage
