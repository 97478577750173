import { Toast } from "primereact/toast"
import { useEffect } from "react"
import { useRef } from "react"
import { useApp } from "../../hook/useApp"

export const Notification = ({ position }) => {
  const toast = useRef(null)

  const { store } = useApp()

  useEffect(() => {
    store.UIInterface.setToast(toast)
  }, [])

  return <Toast ref={toast} position={position} />
}
