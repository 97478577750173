import { useTranslation } from "react-i18next"

export const ForkNotFound = ({ children }) => {
  const { t } = useTranslation()

  return (
    <div className="forks-not-found">
      {children ? (
        children
      ) : (
        <>
          <h2>{t("notification.not-found")}</h2>
          <p>{t("notification.info")}</p>
        </>
      )}
    </div>
  )
}
