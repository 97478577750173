import { useEffect, useState } from "react"
import Main from "../../../components/layout/main/Main"
import { AboutRu } from "./About/AboutRu"
import { AboutEn } from "./About/AboutEn"

const Markup = (language) => {
  switch (language) {
    case "ru":
      return <AboutRu />
    case "en":
      return <AboutEn />
  }
}

export const About = () => {
  const [language, setLanguage] = useState(null)

  useEffect(() => {
    let language = localStorage.getItem("language")
    setLanguage(language || "ru")
  }, [])

  return <Main>{Markup(language)}</Main>
}
