import { createContext } from "react"
import RootStore from "../stores/index"

export const AppContext = createContext(null)

export const AppProvider = ({ children }) => {
  const store = new RootStore()

  const value = { store }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}
