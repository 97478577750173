import { useTranslation } from "react-i18next"

export const ModalWindow = ({ children, title, handlerClose, size }) => {
  const { t } = useTranslation()

  const sizes = {
    middle: "modal-window--size-middle"
  }

  return (
    <div className={`modal-window${size in sizes ? ` ${sizes[size]}` : ""}`}>
      <div className={`modal-window__title`}>{title || t("filter.filter")}</div>
      <div className="modal-window__close" onClick={handlerClose}></div>
      {children}
    </div>
  )
}

export const BackDrop = ({ children, handlerClose }) => {
  return (
    <div className="backdrop" onClick={handlerClose}>
      {children}
    </div>
  )
}
