import styles from "../autorization.module.css"
import { observer } from "mobx-react-lite"
import { useFormik } from "formik"
import { useApp } from "../../../hook/useApp"
import { classNames } from "primereact/utils"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

const ChangePassword = observer(({ visible, handlerClose }) => {
  const { store } = useApp()
  const { t } = useTranslation()
  const { id } = useParams()

  const formik = useFormik({
    initialValues: {
      password: ""
    },
    validate: (data) => {
      let errors = {}

      if (!data.password) errors.password = t("change-password.error-password")
      return errors
    },
    onSubmit: async (data) => {
      await store.Auth.confirmChangePassword(data, id)
    }
  })

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name])

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    )
  }

  if (!visible) return ""

  return (
    <div className={styles["modal-window"]}>
      <div className={styles["modal-window__close"]} onClick={handlerClose} />
      <div className={styles["modal-window__title"]}>
        {t("change-password.new-password")}
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles["modal-window_row"]}>
          <input
            type="password"
            placeholder={t("change-password.new-password")}
            className={classNames({ "p-error": isFormFieldValid("password") })}
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
          />
          {getFormErrorMessage("password")}
        </div>
        <div
          className={`${styles["modal-window_row"]} ${styles["modal-window_row--center"]}`}
        >
          <div className={styles["modal-window_col"]}>
            <p className="p-error">{store.Auth?.authError || ""}</p>
            <button type="submit" className="app-button">
              {t("change-password.submit")}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
})

export { ChangePassword }
