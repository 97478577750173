import { useEffect, useState } from "react"
import Main from "../../components/layout/main/Main"
import { TermsRu } from "./Terms/TermsRu"
import { TermsEn } from "./Terms/TermsEn"

const Markup = (language) => {
  switch (language) {
    case "ru":
      return <TermsRu />
    case "en":
      return <TermsEn />
  }
}

export const Terms = () => {
  const [language, setLanguage] = useState(null)

  useEffect(() => {
    let language = localStorage.getItem("language")
    setLanguage(language || "ru")
  }, [])

  return <Main>{Markup(language)}</Main>
}
