import { useEffect, useState } from "react"
import Main from "../../../components/layout/main/Main"
import { ForksRu } from "./Forks/ForksRu"
import { ForksEn } from "./Forks/ForksEn"

const Markup = (language) => {
  switch (language) {
    case "ru":
      return <ForksRu />
    case "en":
      return <ForksEn />
  }
}

export const Forks = () => {
  const [language, setLanguage] = useState(null)

  useEffect(() => {
    let language = localStorage.getItem("language")
    setLanguage(language || "ru")
  }, [])

  return <Main>{Markup(language)}</Main>
}
