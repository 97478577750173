import { useTranslation } from "react-i18next"

const TariffsFaqContent = () => {
  const { t } = useTranslation()

  return (
    <div className="tariffs-faq-section__content">
      <h3>{t("tariffs.content-extension")}</h3>
      <p>{t("tariffs.content-price")}</p>
      <h3>{t("tariffs.content-auto")}</h3>
      <p>{t("tariffs.content-write-off")}</p>
    </div>
  )
}

export default TariffsFaqContent
