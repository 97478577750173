import { places, top } from "../constants"
import { useApp } from "../hook/useApp"

export const ROLES_PRO = [
  "PRO_PREMATCH",
  "PRO_PREMATCH_LIVE",
  "PREMIUM_PREMATCH",
  "PREMIUM_PREMATCH_LIVE"
]
export const ROLES_LITE_AND_HIGHER = [
  "LITE",
  "PRO_PREMATCH",
  "PRO_PREMATCH_LIVE",
  "PREMIUM_PREMATCH",
  "PREMIUM_PREMATCH_LIVE"
]
export const ROLES_PREMATCH_LIVE = [
  "PRO_PREMATCH_LIVE",
  "PREMIUM_PREMATCH_LIVE"
]
export const ROLES_PREMATCH = ["PRO_PREMATCH", "PREMIUM_PREMATCH"]
export const hiddenTypeBet = "XXXX"
export const hiddenCoef = "XX"

export function unixDateToDate(date) {
  return date * 1000
}

export function formatDate(date) {
  return new Date(date).toLocaleDateString("ru-RU")
}

export function formatTime(date) {
  return new Date(date).toLocaleTimeString("ru-RU", {
    hour: "numeric",
    minute: "numeric"
  })
}

export function hasTimePassed(seconds, isDesktop = true) {
  seconds = Number(seconds)

  const h = Math.trunc(seconds / 3600)
  const m = Math.trunc((seconds - h * 3600) / 60)
  const s = Math.trunc(seconds - h * 3600 - m * 60)

  if (h > 0) return isDesktop ? `${h} ч ${m} мин` : `${h}:${m} мин`
  return isDesktop ? `${m} мин ${s} сек` : `${m}:${s} сек`
}

export function setUrlAllFork(typeEvents, idEvent) {
  return typeEvents === "LIVE" ? `/live/${idEvent}` : `/line/${idEvent}`
}

export const formatProfit = (profit) => {
  return profit > 0
    ? Number(profit).toFixed(2)
    : Number(profit * 100).toFixed(2)
}

export const formatCoef = (coef) => {
  if (isNaN(Number(coef))) return coef

  const parts = String(coef).split(".")

  if (parts[1]?.length || 0 > 2) return Number(coef).toFixed(2)

  return coef
}

export const textBet = (type, param) => {
  if (!type) return hiddenTypeBet

  const listHandicap = ["1Ф", "2Ф"]
  const listMain = ["01", "1X", "02", "X2", "12", "0X"]

  let typeBet = type.replace("0", "")
  if (listHandicap.includes(typeBet))
    typeBet = typeBet.split("").reverse().join("")

  return `${typeBet} ${param !== null && !listMain.includes(type) ? `(${param / 100})` : ""}`
}

export const IsFavorite = (id) => {
  const { store } = useApp()

  return store.SportEvent.favoriteArr.includes(id)
}

export const checkIsProUser = (userInfo) => {
  if (!userInfo) return false

  const roles = userInfo.roles

  return roles.filter((item) => ROLES_PRO.includes(item)).length > 0
}

export const isLiteVersion = (userInfo) => {
  if (!userInfo) return false

  const roles = userInfo.roles

  return roles.filter((item) => ROLES_LITE_AND_HIGHER.includes(item)).length > 0
}

export const setArrayTimeTypeSport = (userInfo) => {
  if (!userInfo) return []

  const roles = userInfo.roles

  const arrPrematch = [{ label: places[0], value: places[0] }]
  const arrPrematchLive = [{ label: places[0], value: places[0] }]

  const isPrematchLive =
    roles.filter((item) => ROLES_PREMATCH_LIVE.includes(item)).length > 0
  if (isPrematchLive) return arrPrematchLive

  const isPrematch =
    roles.filter((item) => ROLES_PREMATCH.includes(item)).length > 0
  if (isPrematch) return arrPrematch
}

export const isLiveForTopEvents = ({ isLive, sportName }) => {
  if (sportName === top && isLive) return true
  return false
}

export const isFilterTime = ({ value, dateEvent }) => {
  dateEvent = new Date(unixDateToDate(dateEvent))
  const now = new Date()

  const hours = (now - dateEvent) / (1000 * 60 * 60)

  if (value === "ANY_TIME") return true

  const list = {
    ONE_HOUR: 1,
    TWO_HOURS: 2,
    TWELVE_HOURS: 12,
    ONE_DAY: 24,
    TWO_DAYS: 48,
    SEVEN_DAYS: 168
  }

  return hours < list[value]
}

export const setSubscribeInfo = (tariff) => {
  const { title, period, timeEvents } = tariff

  const days = period.find((item) => item.active)?.value
  const timeEvent =
    timeEvents.length > 1
      ? timeEvents
          .filter((item) => item.active)
          .map((item) => item.name)
          .join("_")
      : null

  const name = `${title}${timeEvent ? `_${timeEvent}` : ""}`.toUpperCase()

  return { role: name, countDays: days, paymentSystem: "FREEKASSA" }
}

export const getIconBySport = ({ sportName, sports }) => {
  return sports.find((item) => item.filter === sportName)?.type || null
}
export const getSportNameBySportName = (sportName) => {
  const list = {}

  if (sportName in list) return list[sportName]
  return sportName
}
