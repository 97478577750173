import { useFormik } from "formik"
import { classNames } from "primereact/utils"
import { useApp } from "../../../../../hook/useApp"
import { useTranslation } from "react-i18next"

const ProfileCardFormEdit = () => {
  const { store } = useApp()
  const { t } = useTranslation()

  const changeError = store.Auth.authError ? (
    <p className="profile-settings-card__edit-input--w-100 p-error">
      {store.Auth.authError}
    </p>
  ) : (
    ""
  )

  const changeSuccess = store.Auth.successChangeUserInfo ? (
    <p className="profile-settings-card__edit-input--w-100 p-success">
      {store.Auth.successChangeUserInfo}
    </p>
  ) : (
    ""
  )

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: ""
    },
    validate: (data) => {
      let errors = {}

      if (!data.username) {
        errors.username = t("sign-up.error-name")
      }

      if (data.username.length > 30) {
        errors.username = t("sign-up.error-name-length")
      }

      if (!data.email) {
        errors.email = t("sign-up.error-email")
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
      ) {
        errors.email = t("sign-up.error-email-pattern")
      }

      if (data.oldPassword.length < 5) {
        errors.oldPassword = t("sign-up.error-password-min")
      }

      if (data.oldPassword.length > 30) {
        errors.oldPassword = t("sign-up.error-password-max")
      }

      if (!data.oldPassword) {
        errors.oldPassword = t("sign-up.error-password")
      }

      if (data.newPassword.length < 5) {
        errors.newPassword = t("sign-up.error-password-min")
      }

      if (data.newPassword.length > 30) {
        errors.newPassword = t("sign-up.error-password-max")
      }

      if (!data.newPassword) {
        errors.newPassword = t("sign-up.error-password")
      }

      if (!data.confirmPassword) {
        errors.confirmPassword = t("sign-up.error-password-confirm")
      }

      if (data.newPassword !== data.confirmPassword) {
        errors.confirmPassword = t("sign-up.error-password-match")
      }

      return errors
    },
    onSubmit: async (data, actions) => {
      store.Auth.setAuthError("")
      store.Auth.setSuccessChangeUserInfo("")
      await store.Auth.userChangePassword(data)
      actions.resetForm()
    }
  })

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name])

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    )
  }

  return (
    <form
      className="profile-settings-card__edit"
      onSubmit={formik.handleSubmit}
    >
      <div className="input__wrapper profile-settings-card__edit-input--w-50">
        <input
          className={classNames({
            "p-error": isFormFieldValid("username"),
            "input-el input-el--size-md input-el--theme-white profile-settings-card__edit-input": true
          })}
          type="text"
          name="username"
          placeholder={t("sign-up.name")}
          maxLength="35"
          value={formik.values.username}
          onChange={formik.handleChange}
        />
        {getFormErrorMessage("username")}
      </div>
      <div className="input__wrapper profile-settings-card__edit-input--w-50">
        <input
          className={classNames({
            "p-error": isFormFieldValid("email"),
            "input-el input-el--size-md input-el--theme-white profile-settings-card__edit-input": true
          })}
          type="text"
          name="email"
          placeholder="E-mail"
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        {getFormErrorMessage("email")}
      </div>
      <div className="input__wrapper profile-settings-card__edit-input--w-50">
        <input
          className={classNames({
            "p-error": isFormFieldValid("newPassword"),
            "input-el input-el--size-md input-el--theme-white profile-settings-card__edit-input": true
          })}
          type="password"
          name="newPassword"
          placeholder={t("account.new-password")}
          value={formik.values.newPassword}
          onChange={formik.handleChange}
        />
        {getFormErrorMessage("newPassword")}
      </div>
      <div className="input__wrapper profile-settings-card__edit-input--w-50">
        <input
          className={classNames({
            "p-error": isFormFieldValid("confirmPassword"),
            "input-el input-el--size-md input-el--theme-white profile-settings-card__edit-input": true
          })}
          type="password"
          name="confirmPassword"
          placeholder={t("account.confirm-password")}
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
        />
        {getFormErrorMessage("confirmPassword")}
      </div>
      <div className="input__wrapper input__wrapper--w-100">
        <input
          className={classNames({
            "p-error": isFormFieldValid("oldPassword"),
            "input-el input-el--size-md input-el--theme-white profile-settings-card__edit-input": true
          })}
          type="password"
          name="oldPassword"
          placeholder={t("account.old-password")}
          value={formik.values.oldPassword}
          onChange={formik.handleChange}
        />
        {getFormErrorMessage("oldPassword")}
      </div>
      <button
        className="btn btn--theme-dark btn--size-st profile-settings-card__edit-btn"
        type="submit"
      >
        {t("account.submit")}
      </button>
      {changeError}
      {changeSuccess}
    </form>
  )
}

export default ProfileCardFormEdit
