import { useTranslation } from "react-i18next"
import Main from "../../../components/layout/main/Main"

export const Feature = () => {
  const { t } = useTranslation()

  return (
    <Main>
      <h1 className="main__title">{t("pages.feature-title")}</h1>
      <h2>{t("pages.feature-1")}</h2>
      <p>{t("pages.feature-2")}</p>
      <h2>{t("pages.feature-3")}</h2>
      <p>{t("pages.feature-4")}</p>
      <h2>{t("pages.feature-5")}</h2>
      <p>{t("pages.feature-6")}</p>
    </Main>
  )
}
