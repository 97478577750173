import React from "react"
import { Dropdown } from "primereact/dropdown"
import { useState } from "react"
import { useApp } from "../../../../hook/useApp"
import { useTranslation } from "react-i18next"
import TariffsSectionSliders from "../section/sliders/TariffsSectionSliders"
import { setSubscribeInfo } from "../../../../helpers"

export const TariffSlider = () => {
  const { store } = useApp()
  const { t } = useTranslation()

  // const isMobile = store.UIInterface.is800px;

  const toast = store.UIInterface.toast

  const prices = {
    "Lite/Prematch": [
      { days: 7, price: 300 },
      { days: 30, price: 600 },
      { days: 90, price: 1200 },
      { days: 180, price: 1800 },
      { days: 365, price: 3000 }
    ],
    "Pro/Prematch": [
      { days: 7, price: 500 },
      { days: 30, price: 1000 },
      { days: 90, price: 2000 },
      { days: 180, price: 3000 },
      { days: 365, price: 5000 }
    ],
    "Pro/Prematch/Live": [
      { days: 7, price: 600 },
      { days: 30, price: 1200 },
      { days: 90, price: 2400 },
      { days: 180, price: 3500 },
      { days: 365, price: 5500 }
    ],
    "Premium/Prematch": [
      { days: 7, price: 900 },
      { days: 30, price: 1800 },
      { days: 90, price: 3600 },
      { days: 180, price: 5500 },
      { days: 365, price: 8000 }
    ],
    "Premium/Prematch/Live": [
      { days: 7, price: 1200 },
      { days: 30, price: 2400 },
      { days: 90, price: 4800 },
      { days: 180, price: 7200 },
      { days: 365, price: 11000 }
    ]
  }

  const [tariffs, setTariffs] = useState([
    {
      id: "tariff_1",
      title: "Free",
      timeEvents: [{ id: "t1_1", name: "Prematch" }],
      period: [],
      maxProfit: 3,
      description: t("tariffs.auth-only"),
      price: null,
      prolongation: ""
    },
    {
      id: "tariff_2",
      title: "Lite",
      timeEvents: [{ id: "t2_1", name: "Prematch", active: true }],
      period: [
        { id: "p2_1", value: 7, label: 7, active: true },
        { id: "p2_2", value: 30, label: 30 },
        { id: "p2_3", value: 90, label: 90 },
        { id: "p2_4", value: 180, label: 180 },
        { id: "p2_5", value: 365, label: 365 }
      ],
      maxProfit: 10,
      description: t("tariffs.extension"),
      price: prices[`Lite/Prematch`][0].price,
      prolongation: t("tariffs.prolongation")
    },
    {
      id: "tariff_3",
      title: "Pro",
      timeEvents: [
        { id: "t3_1", name: "Prematch", active: true },
        { id: "t3_2", name: "Live", active: false }
      ],
      period: [
        { id: "p3_1", value: 7, label: 7, active: true },
        { id: "p3_2", value: 30, label: 30 },
        { id: "p3_3", value: 90, label: 90 },
        { id: "p3_4", value: 180, label: 180 },
        { id: "p3_5", value: 365, label: 365 }
      ],
      maxProfit: 50,
      description: t("tariffs.extension"),
      price: prices[`Pro/Prematch`][0].price,
      prolongation: t("tariffs.prolongation")
    },
    {
      id: "tariff_4",
      title: "Premium",
      timeEvents: [
        { id: "t4_1", name: "Prematch", active: true },
        { id: "t4_2", name: "Live", active: false }
      ],
      period: [
        { id: "p4_1", value: 7, label: 7, active: true },
        { id: "p4_2", value: 30, label: 30 },
        { id: "p4_3", value: 90, label: 90 },
        { id: "p4_4", value: 180, label: 180 },
        { id: "p4_5", value: 365, label: 365 }
      ],
      maxProfit: "∞",
      description: t("tariffs.extension"),
      price: prices[`Premium/Prematch`][0].price,
      prolongation: t("tariffs.prolongation")
    }
  ])

  function changePeriod({ value, id }) {
    const tarif = tariffs.find((item) => item.id === id)
    if (tarif) {
      tarif.period = tarif.period.map((item) => {
        if ("active" in item) delete item.active
        if (item.value === value) {
          item.active = true
        }

        return item
      })
    }

    setTariffs([...setPriceTariffs(tariffs)])
  }

  function changeTimeEvents({ tarifID, timeID }) {
    const tarif = tariffs.find((item) => item.id === tarifID)
    if (tarif) {
      tarif.timeEvents = tarif.timeEvents.map((item, index, array) => {
        if (array.length > 1 && index > 0 && item.id === timeID) {
          item.active = !item.active
        }

        return item
      })
    }

    setTariffs([...setPriceTariffs(tariffs)])
  }

  function setPriceTariffs(tariffs) {
    return tariffs.map((item) => {
      const { title, timeEvents, period } = item

      const activeTimeEvents = timeEvents
        .filter((item) => item.active)
        .map((item) => item.name)
        .join("/")
      if (!activeTimeEvents) return item

      const namePrice = `${title}/${activeTimeEvents}`
      const activePeriod = period.find((item) => item.active)?.value

      const price = prices[namePrice].find(
        (item) => item.days === activePeriod
      )?.price

      item.price = price

      return item
    })
  }

  async function paymentSubscribe(tariffID) {
    const tariff = tariffs.find((item) => item.id === tariffID)
    if (tariff?.price) {
      const tariffInfo = setSubscribeInfo(tariff)

      const status = await store.Payment.setPayment(tariffInfo)
      if (!status.ok) {
        return toast.current.show({
          severity: "warning",
          summary: t("footer.error"),
          detail: status.text,
          life: 10000
        })
      }

      window.location.href = status.url
    }
  }

  // if (isMobile) {
  //   return (
  //     <TariffsSectionSliders>
  //       <Carousel value={tariffs} itemTemplate={TariffSliderCard} numVisible={1} />
  //     </TariffsSectionSliders>
  //   )
  // }

  return (
    <TariffsSectionSliders>
      {tariffs.map((item) => {
        return (
          <TariffSliderCard
            {...item}
            key={item.id}
            changePeriod={changePeriod}
            changeTimeEvents={changeTimeEvents}
            paymentSubscribe={paymentSubscribe}
          />
        )
      })}
    </TariffsSectionSliders>
  )
}

const TariffSliderCard = ({
  id,
  title,
  timeEvents,
  period,
  maxProfit,
  changePeriod,
  changeTimeEvents,
  paymentSubscribe,
  description,
  prolongation,
  price
}) => {
  const { t } = useTranslation()

  const activeValue = period.find((item) => item.active)

  return (
    <div className="tariffs-card">
      <div className="tariffs-card__title">{title}</div>
      <div className="tariffs-card_types-sport">
        {timeEvents.map((item, index, array) => {
          return (
            <React.Fragment key={item.id}>
              <div
                className={`tariffs-card_types-sport__type${
                  item.active && array.length > 1 ? " active" : ""
                }`}
                onClick={() =>
                  changeTimeEvents({ tarifID: id, timeID: item.id })
                }
              >
                {item.name}
              </div>
              {array.length > 1 && index < array.length - 1 ? (
                <div className="tariffs-card_types-sport__plus">+</div>
              ) : (
                ""
              )}
            </React.Fragment>
          )
        })}
      </div>
      <div className="tariffs-card__info">
        {period.length > 0 ? (
          <div className="tariffs-card__period">
            <p>{t("tariffs.period")}</p>
            <Dropdown
              options={period}
              value={activeValue.value}
              onChange={(e) => changePeriod({ value: e.value, id })}
            />
          </div>
        ) : (
          ""
        )}
        <div className="tariffs-card__profit">
          {t("tariffs.max-profit")} {maxProfit}
          {maxProfit > 0 ? "%" : ""}
        </div>
      </div>
      <div className="tariffs-card__description">
        <p>{description}</p>
      </div>
      <div className="tariffs-card__price-info">
        {price ? (
          <div className="tariffs-card__price">
            {price} {t("tariffs.rub")}
          </div>
        ) : (
          ""
        )}
        <button
          className="tariffs-card__button"
          onClick={() => paymentSubscribe(id)}
        >
          {price ? t("tariffs.switch") : t("tariffs.free")}
        </button>
      </div>
      <div className="tariffs-card__prolongation">
        <p>{prolongation}</p>
      </div>
    </div>
  )
}
