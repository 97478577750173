import AsideNavigationLink from "./AsideNavigationLink"
import { useTranslation } from "react-i18next"

const AsideNavigation = () => {
  const { t } = useTranslation()

  const urls = [
    { id: 1, text: t("account.account"), url: "account" },
    { id: 2, text: t("account.settings"), url: "settings" },
    { id: 6, text: t("account.payment"), url: "payments" }
  ]

  return (
    <nav className="profile-aside__nav">
      <ul className="profile-aside__nav-list">
        {urls.map((item) => {
          return (
            <AsideNavigationLink key={item.id} url={item.url}>
              {item.text}
            </AsideNavigationLink>
          )
        })}
      </ul>
    </nav>
  )
}

export default AsideNavigation
