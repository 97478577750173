import Main from "../../../components/layout/main/Main"
import { useTranslation } from "react-i18next"

export const HowRecharge = () => {
  const { t } = useTranslation()

  return (
    <Main>
      <h1 className="main__title">{t("pages.recharge-title")}</h1>
      <p>{t("pages.recharge-main")}</p>
    </Main>
  )
}
