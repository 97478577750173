import { useApp } from "../../../hook/useApp"
import { observer } from "mobx-react-lite"
import {
  FilterTypeTimeSport,
  FilterProfit,
  FilterTime,
  FilterFilter,
  FilterRefreshEvents,
  FilterAutoRefreshEvents,
  FilterSports
} from "./filters/HomeFilters"
import FilterParams from "./filters/FilterParams"
import { isLiteVersion } from "../../../helpers/index"

export const HomeContentHeader = observer(() => {
  const { store } = useApp()

  const isAuth = store.Auth.authUser

  const isProUser = store.UIInterface.isProUser
  const isMobile = store.UIInterface.isMobile
  const isPrematch = store.SportEvent.isPrematch
  const liteVersion = isLiteVersion(isAuth)

  return (
    <>
      <FilterParams visible={isAuth} />
      <HomeContentHeaderItem visible={isProUser && isMobile}>
        <FilterTypeTimeSport />
      </HomeContentHeaderItem>
      <HomeContentHeaderItem visible={isProUser}>
        <FilterSports mobile={isMobile} />
      </HomeContentHeaderItem>
      <HomeContentHeaderItem>
        <div className="display--flex">
          <FilterProfit visible={isProUser} />
          <FilterRefreshEvents visible={false} mobile={isMobile} />
          <FilterTime visible={isProUser && isPrematch} />
          <FilterAutoRefreshEvents
            visible={isProUser && !isPrematch}
            mobile={isMobile}
          />
          <FilterFilter visible={liteVersion} />
        </div>
        <FilterTypeTimeSport visible={isProUser && !isMobile} />
      </HomeContentHeaderItem>
    </>
  )
})

export const HomeContentHeaderItem = ({ children, visible = true }) => {
  if (!visible) return ""

  return <div className="feed-section__header">{children}</div>
}
