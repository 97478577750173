import Main from "../../../components/layout/main/Main"
import { useTranslation } from "react-i18next"

export const HowPlaceBet = () => {
  const { t } = useTranslation()

  return (
    <Main>
      <h1 className="main__title">{t("pages.place-bet-title")}</h1>
      <p>{t("pages.place-bet-1")}</p>
      <p>{t("pages.place-bet-2")}</p>
      <p>{t("pages.place-bet-3")}</p>
      <p>{t("pages.place-bet-4")}</p>
    </Main>
  )
}
