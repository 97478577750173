import { observer } from "mobx-react-lite"
import { useApp } from "../../../../../hook/useApp"
import { formatDate, formatTime } from "../../../../../helpers"
import { useTranslation } from "react-i18next"

const ProfileCardTable = observer(() => {
  const { store } = useApp()
  const { t } = useTranslation()

  const userInfo = store.Auth.infoUser

  const expirationDate = userInfo?.subscription?.expirationDate
  const role = userInfo?.subscription?.role

  return (
    <table className="profile-settings-card__subscription">
      <thead>
        <tr>
          <th>{t("account.tariff")}</th>
          <th>{t("account.expires")}</th>
          <th>{t("account.available-days")}</th>
          <th>{t("account.status")}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td data-table-title-mobile={t("account.tariff")}>{role || "-"}</td>
          <td data-table-title-mobile={t("account.expires")}>
            {expirationDate
              ? `${formatDate(expirationDate)} ${formatTime(expirationDate)}`
              : role
                ? t("account.unlimited")
                : "-"}
          </td>
          <td data-table-title-mobile={t("account.available-days")}>-</td>
          <td data-table-title-mobile={t("account.status")}>
            {role ? <div>{t("account.active")}</div> : "-"}
          </td>
        </tr>
      </tbody>
    </table>
  )
})

export default ProfileCardTable
