import Main from "../../components/layout/account/Main"
import ProfileLayout from "../../components/layout/account/ProfileLayout"

import Aside from "../../components/layout/account/aside/Aside"
import AsideHeader from "../../components/layout/account/aside/AsideHeader"
import AsideNavigation from "../../components/layout/account/aside/AsideNavigation"
import AsideMobileMenu from "../../components/layout/account/aside/AsideMobileMenu"

import ProfileTitle from "../../components/layout/account/ProfileTitle"

import ProfileMain from "../../components/layout/account/main/ProfileMain"
import ProfileCard from "../../components/layout/account/main/card/ProfileCard"
import ProfileCardHeader from "../../components/layout/account/main/card/header/ProfileCardHeader"
import ProfileCardHeaderTitle from "../../components/layout/account/main/card/header/ProfileCardHeaderTitle"

import ProfileCardTable from "../../components/layout/account/main/card/ProfileCardTable"
import ProfileCardDescription from "../../components/layout/account/main/card/ProfileCardDescription"

import ProfileCardFormPromocode from "../../components/layout/account/main/card/ProfileCardFormPromocode"

import ProfileCardFormEdit from "../../components/layout/account/main/card/ProfileCardFormEdit"

import { useApp } from "../../hook/useApp"
import { useTranslation } from "react-i18next"
import { observer } from "mobx-react-lite"
import { Link } from "react-router-dom"

const Account = observer(() => {
  const { store } = useApp()
  const { t } = useTranslation()
  const isMobile = store.UIInterface.is800px

  return (
    <Main>
      <ProfileLayout>
        <Aside>
          <AsideHeader />
          <AsideNavigation />
          {isMobile ? <AsideMobileMenu /> : ""}
        </Aside>
        {isMobile ? <ProfileTitle>{t("account.account")}</ProfileTitle> : ""}
        <ProfileMain>
          <ProfileCard>
            <ProfileCardHeader>
              <ProfileCardHeaderTitle>
                {t("account.subscriptions")}
              </ProfileCardHeaderTitle>
              <Link to="/tariffs" className="normal-a">
                <button className="btn btn--theme-dark btn--size-st profile-settings-card__btn">
                  {t("account.buy-subscription")}
                </button>
              </Link>
            </ProfileCardHeader>
            <ProfileCardTable />
            <ProfileCardDescription>
              {t("account.freeze-subscription")}
            </ProfileCardDescription>
          </ProfileCard>
          <ProfileCard>
            <ProfileCardHeader>
              <ProfileCardHeaderTitle>
                {t("account.promocodes")}
              </ProfileCardHeaderTitle>
            </ProfileCardHeader>
            <ProfileCardFormPromocode />
          </ProfileCard>
          <ProfileCard>
            <ProfileCardHeader>
              <ProfileCardHeaderTitle>
                {t("account.change-information")}
              </ProfileCardHeaderTitle>
            </ProfileCardHeader>
            <ProfileCardFormEdit />
          </ProfileCard>
        </ProfileMain>
      </ProfileLayout>
    </Main>
  )
})

export { Account }
