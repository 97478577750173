import { ProgressSpinner } from "primereact/progressspinner"
import { useTranslation } from "react-i18next"

const Progress = ({ visible }) => {
  const { t } = useTranslation()

  if (!visible) return ""

  return (
    <div className="progress-spinner">
      <ProgressSpinner
        style={{ width: "70px", height: "70px" }}
        strokeWidth="6"
      />
      <span>{t("notification.loading")}</span>
    </div>
  )
}

export default Progress
