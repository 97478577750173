import { Link } from "react-router-dom"

export const RefundRu = () => {
  return (
    <>
      <h1 className="main__title">Политика возврата денежных средств</h1>
      <h2>Условия</h2>
      <p>
        Если покупатель не удовлетворён качеством оказываемых сервисом услуг, он
        вправе запросить возврат потраченных на покупку подписки средств в
        течение 3-х суток с моменты покупки.
      </p>
      <p>
        Исключением являются случаи нарушения 
        <Link to="/terms">Пользовательского соглашения</Link> и{" "}
        <Link to="/privacy">Политики приватности</Link>, в этих случаях
        программа возврата средств не действует.
      </p>
      <p>
        Множественный возврат средств производится на усмотрение администрации.
      </p>
      <h2>Подробности и сроки</h2>
      <p>
        Денежные средства возвращаются по реквизитам, с которых была произведена
        оплата. Если покупка была оплачена банковской картой, средства будут
        возвращены на карту, с которой была совершена оплата.
      </p>
      <p>
        Сроки возврата могут достигать до 30 календарных дней̆ с момента
        отключения услуги пользователю в зависимости от выбора метода оплаты.
      </p>
      <h2>Нестандартные ситуации</h2>
      <p>
        При возникновении ситуаций, связанных с техническими проблемами (двойное
        списание, "зависание", долгая обработка платежа), необходимо обратиться
        по контактам, указанным ниже.
      </p>
      <h2>Инструкции</h2>
      <p>
        Для возврата денежных средств необходимо связаться с менеджером по
        email: <span>workbet.info@ya.ru</span> и получить дальнейшие инструкции.
      </p>
      <p>
        Сроки рассмотрения заявки на возврат денежных средств 1-2 рабочих дня.
      </p>
    </>
  )
}
