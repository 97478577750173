import FooterMenuItem from "./FooterMenuItem"
import { useTranslation } from "react-i18next"

const FooterMenu = () => {
  const { t } = useTranslation()

  const menuItems = [
    [
      { id: "footer_1_1", text: t("footer.main"), url: "/" },
      { id: "footer_1_2", text: t("footer.forks"), url: "/forks" },
      { id: "footer_1_3", text: t("footer.feature"), url: "/feature" },
      { id: "footer_1_4", text: t("footer.tariffs"), url: "/tariffs" },
      { id: "footer_1_5", text: t("footer.promotion"), url: "/promotion" }
    ],
    [
      { id: "footer_2_1", text: t("footer.information"), url: "#" },
      { id: "footer_2_2", text: t("footer.about"), url: "/about" },
      { id: "footer_2_3", text: t("footer.rules"), url: "/rules" },
      { id: "footer_2_4", text: t("footer.payments"), url: "/payments" },
      { id: "footer_2_5", text: t("footer.refund"), url: "/refund" }
    ],
    [
      { id: "footer_3_1", text: t("footer.start"), url: "#" },
      {
        id: "footer_3_2",
        text: t("footer.start-winning"),
        url: "/start-winning"
      },
      {
        id: "footer_3_3",
        text: t("footer.how-place-bet"),
        url: "/how-place-bet"
      },
      {
        id: "footer_3_4",
        text: t("footer.how-recharge"),
        url: "/how-recharge"
      },
      {
        id: "footer_3_5",
        text: t("footer.how-withdraw-money"),
        url: "/how-withdraw-money"
      },
      {
        id: "footer_3_6",
        text: t("footer.identification"),
        url: "/identification"
      }
    ],
    [
      { id: "footer_4_1", text: t("footer.support"), url: "#" },
      {
        id: "footer_4_2",
        text: t("footer.legal-requests"),
        url: "/legal-requests"
      },
      {
        id: "footer_4_4",
        text: t("footer.tax-requests"),
        url: "/tax-requests"
      },
      { id: "footer_4_5", text: t("footer.faq"), url: "/faq" }
    ]
  ]

  const menu = menuItems.map((col) => {
    return (
      <div
        className="footer-nav footer__nav-list-item"
        key={Math.random().toString(32).substring(2, 8)}
      >
        {col.map((item) => {
          return (
            <FooterMenuItem key={item.id} url={item?.url}>
              {item.text}
            </FooterMenuItem>
          )
        })}
      </div>
    )
  })

  return <div className="footer__nav-list">{menu}</div>
}

export default FooterMenu
