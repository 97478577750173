import Logo from "../../ui/logo/Logo"
import {
  HeaderMenu,
  HeaderMenuNavigation,
  HeaderMenuNavigationItem,
  HeaderBurgerMenuShow,
  HeaderBurgerMenuHide
} from "./menu/HeaderMenu"
import { Profile, ProfileItem } from "./profile/Profile"
import { SignIn } from "../../autorization/signin/SignIn"
import { SignUp } from "../../autorization/signup/SignUp"
import { Reset } from "../../autorization/reset/Reset"
import { ChangePassword } from "../../autorization/changePassword/ChangePassword"
import { Backdrop } from "../../autorization/Backdrop"
import ButtonWriteToUs from "../../buttons/ButtonWriteToUsMobile"
import LogoutMessage from "./LogoutMessage"

import { Button } from "../../buttons/Button"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { Link } from "react-router-dom"
import { useEffect } from "react"

import { useApp } from "../../../hook/useApp"

const Header = observer(() => {
  const { store } = useApp()
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    store.Auth.checkPathForModal(pathname)
  }, [pathname])

  const isSignIn = store.Auth.isSignIn
  const isSignUp = store.Auth.isSignUp
  const isReset = store.Auth.isReset
  const isChangePassword = store.Auth.isChangePassword
  const authUser = store.Auth.authUser
  const isMobile = store.UIInterface.isMobile
  const isShowMenu = store.UIInterface.isShowMenu
  const logoutMessage = store.UIInterface.logoutMessage

  if ((isSignIn || isSignUp) && isMobile && isShowMenu) {
    store.UIInterface.changeStateMenu()
  }

  const navigationItems = [
    { id: "nav_1", text: t("footer.main"), url: "/" },
    { id: "nav_2", text: t("footer.forks"), url: "/forks" },
    { id: "nav_3", text: t("footer.feature"), url: "/feature" },
    { id: "nav_4", text: t("footer.tariffs"), url: "/tariffs" },
    { id: "nav_5", text: t("footer.promotion"), url: "/promotion" }
  ]

  function showModalWriteToUs() {
    store.UIInterface.changeStateModalWriteToUs({
      type: "title",
      value: t("buttons.contact-us")
    })
    store.UIInterface.changeStateModalWriteToUs({
      type: "type",
      value: t("buttons.appeal")
    })
    store.UIInterface.changeStateModalWriteToUs({
      type: "visible",
      value: true
    })
  }

  function closeModalWIndow(e) {
    const clickClass = e.target.className
    if (!clickClass.includes("modal-window__close")) return false

    navigate("/", { replace: false })
  }

  function handleClick() {
    store.UIInterface.changeStateMenu()
  }

  function logout() {
    store.Auth.logout()
    handleClick()
  }

  return (
    <header className="header">
      <div className="container header__container">
        <Logo />
        <HeaderMenu>
          <HeaderMenuNavigation>
            {navigationItems.map((item) => {
              return (
                <HeaderMenuNavigationItem key={item.id}>
                  <Link to={item.url} className="header-nav__link">
                    {item.text}
                  </Link>
                </HeaderMenuNavigationItem>
              )
            })}
            {
              <HeaderMenuNavigationItem handler={showModalWriteToUs}>
                <span className="header-nav__link">{t("footer.support")}</span>
              </HeaderMenuNavigationItem>
            }
          </HeaderMenuNavigation>
          <Profile>
            {authUser ? (
              <>
                <Link to="/profile/account" onClick={handleClick}>
                  <Button
                    theme="white-accent"
                    className="padding-rl--40 btn-group__item"
                  >
                    {t("account.account")}
                  </Button>
                </Link>
                <Button
                  handler={logout}
                  theme="dark"
                  className="padding-rl--20 btn-group__item"
                >
                  {t("footer.exit")}
                </Button>
              </>
            ) : (
              <>
                <Link to="/signin">
                  <ProfileItem theme="dark">{t("footer.enter")}</ProfileItem>
                </Link>
                <Link to="/signup">
                  <ProfileItem theme="white">
                    {t("footer.registration")}
                  </ProfileItem>
                </Link>
              </>
            )}
          </Profile>
          <HeaderBurgerMenuHide />
          <ButtonWriteToUs>{t("footer.write-us")}</ButtonWriteToUs>
        </HeaderMenu>
        <HeaderBurgerMenuShow />
      </div>
      <Backdrop
        visible={
          (isSignUp || isSignIn || isReset || isChangePassword) && !authUser
        }
      >
        <SignUp visible={isSignUp} handlerClose={closeModalWIndow} />
        <SignIn visible={isSignIn} handlerClose={closeModalWIndow} />
        <Reset visible={isReset} handlerClose={closeModalWIndow} />
        <ChangePassword
          visible={isChangePassword}
          handlerClose={closeModalWIndow}
        />
      </Backdrop>
      {logoutMessage && <LogoutMessage />}
    </header>
  )
})

export default Header
