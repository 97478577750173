import SockJS from "sockjs-client"
import { useApp } from "../hook/useApp"

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL
let recInterval = null
let socket = null

export function useSocket() {
  const { store } = useApp()

  const onSocketDelete = () => {
    clearInterval(recInterval)
    socket.close()
    socket = null
    console.log("Socket deleted")
  }

  const onSocketOpen = (query) => {
    if (socket && socket.url === `${REACT_APP_BACKEND_URL}sock-bets${query}`) {
      return
    }

    if (socket) {
      onSocketDelete()
    }

    socket = new SockJS(`${REACT_APP_BACKEND_URL}sock-bets${query}`)

    clearInterval(recInterval)

    socket.onopen = () => {
      console.log("Socket connected")
    }

    socket.onmessage = (e) => {
      const message = JSON.parse(e.data)

      if ("status" in message) {
        store.Auth.logout()
        store.UIInterface.setLogoutMessage()
      }

      if ("type" in message) {
        store.SportEvent.updateArrayEvents(message)
      }
    }

    socket.onerror = () => {
      console.log("Socket error")
    }

    socket.onclose = () => {
      console.log("Socket closed")
      socket = null
      // recInterval = setInterval(function () {
      //   onSocketOpen(query)
      // }, 5000)
    }
  }

  return {
    onSocketDelete,
    onSocketOpen
  }
}
