import { IconAction } from "../../components/ui/icons/Icons"

import { Link } from "react-router-dom"
import { Dropdown } from "primereact/dropdown"
import { useTranslation } from "react-i18next"
import { observer } from "mobx-react-lite"

import Main from "../../components/layout/account/Main"
import ProfileLayout from "../../components/layout/account/ProfileLayout"
import Aside from "../../components/layout/account/aside/Aside"
import AsideHeader from "../../components/layout/account/aside/AsideHeader"
import AsideNavigation from "../../components/layout/account/aside/AsideNavigation"
import AsideMobileMenu from "../../components/layout/account/aside/AsideMobileMenu"

import ProfileTitle from "../../components/layout/account/ProfileTitle"

import ProfileMain from "../../components/layout/account/main/ProfileMain"
import ProfileCard from "../../components/layout/account/main/card/ProfileCard"
import ProfileCardHeader from "../../components/layout/account/main/card/header/ProfileCardHeader"
import ProfileCardHeaderTitle from "../../components/layout/account/main/card/header/ProfileCardHeaderTitle"

import BookmakersTable from "../../components/layout/account/bookmakers/BookmakersTable"
import BookmakersTableRow from "../../components/layout/account/bookmakers/BookmakersTableRow"
import BookmakersTableCol from "../../components/layout/account/bookmakers/BookmakersTableCol"
import BookmakersTableTitle from "../../components/layout/account/bookmakers/BookmakersTableTitle"
import BookmakersTableName from "../../components/layout/account/bookmakers/BookmakersTableName"
import BookmakersTableCheckbox from "../../components/layout/account/bookmakers/BookmakersTableCheckbox"

import BookmakersTableCapabilities from "../../components/layout/account/bookmakers/capabilities/BookmakersTableCapabilities"
import BookmakersTableCapabilitiesItem from "../../components/layout/account/bookmakers/capabilities/BookmakersTableCapabilitiesItem"
import { useState } from "react"
import { useApp } from "../../hook/useApp"

const Bookmakers = observer(() => {
  const { store } = useApp()
  const { t } = useTranslation()

  const languages = [
    {
      label: t("bookmakers.choose-language"),
      value: t("bookmakers.choose-language")
    },
    { label: t("bookmakers.russian"), value: t("bookmakers.russian") },
    { label: t("bookmakers.english"), value: t("bookmakers.english") },
    { label: t("bookmakers.german"), value: t("bookmakers.german") }
  ]

  const [bookmakers, setBookmakers] = useState([
    {
      id: 1,
      name: "1xbet",
      capabilities: [1, 2],
      language: t("bookmakers.choose-language")
    },
    {
      id: 2,
      name: "Fonbet",
      capabilities: [1, 2, 3],
      language: t("bookmakers.choose-language")
    },
    {
      id: 3,
      name: "BetCity",
      capabilities: [1],
      language: t("bookmakers.choose-language")
    }
  ])

  function changeLanguage(val, id) {
    const current = bookmakers.find((item) => item.id === id)
    current.language = val

    setBookmakers([...bookmakers])
  }

  const isMobile = store.UIInterface.is800px

  return (
    <Main>
      <ProfileLayout>
        <Aside>
          <AsideHeader />
          <AsideNavigation />
          {isMobile ? <AsideMobileMenu /> : ""}
        </Aside>
        {isMobile ? (
          <ProfileTitle>{t("bookmakers.bookmakers")}</ProfileTitle>
        ) : (
          ""
        )}
        <ProfileMain>
          <ProfileCard>
            <ProfileCardHeader>
              <div className="flex-row width-max margin-bottom-m">
                <ProfileCardHeaderTitle>
                  {t("bookmakers.mirrors")}
                </ProfileCardHeaderTitle>
                <Link
                  to="#"
                  className="profile-settings-card__b-header-settings"
                >
                  {t("bookmakers.settings")}
                </Link>
              </div>
              <div className="width-max margin-none-child">
                <form
                  className="search-form profile-settings-card__b-header-search"
                  action="#"
                >
                  <input
                    className="input-el search-form__input"
                    type="search"
                    name="q"
                    required="required"
                    placeholder={t("bookmakers.find-bookmaker")}
                  />
                  <button className="search-form__btn" type="submit">
                    <IconAction name="search" />
                  </button>
                </form>
              </div>
            </ProfileCardHeader>
            <BookmakersTable>
              <BookmakersTableRow type="header">
                <BookmakersTableCol>
                  <BookmakersTableTitle>
                    {t("bookmakers.bookmaker")}
                  </BookmakersTableTitle>
                </BookmakersTableCol>
                <BookmakersTableCol>
                  <BookmakersTableTitle>
                    {t("bookmakers.domain")}
                  </BookmakersTableTitle>
                </BookmakersTableCol>
                <BookmakersTableCol>
                  <BookmakersTableTitle>
                    {t("bookmakers.features")}
                  </BookmakersTableTitle>
                </BookmakersTableCol>
                <BookmakersTableCol>
                  <BookmakersTableTitle>
                    {t("bookmakers.language")}
                  </BookmakersTableTitle>
                </BookmakersTableCol>
              </BookmakersTableRow>
              {bookmakers.map((item) => {
                return (
                  <BookmakersTableRow key={item.id} type="body">
                    <BookmakersTableCol
                      mobile_title={t("bookmakers.bookmaker")}
                    >
                      <BookmakersTableName>{item.name}</BookmakersTableName>
                    </BookmakersTableCol>
                    <BookmakersTableCol mobile_title={t("bookmakers.domain")}>
                      <BookmakersTableCheckbox />
                    </BookmakersTableCol>
                    <BookmakersTableCol mobile_title={t("bookmakers.features")}>
                      <BookmakersTableCapabilities>
                        {item.capabilities.map((item) => {
                          return (
                            <BookmakersTableCapabilitiesItem
                              key={item + "_" + item}
                              type={item}
                            />
                          )
                        })}
                      </BookmakersTableCapabilities>
                    </BookmakersTableCol>
                    <BookmakersTableCol mobile_title={t("bookmakers.language")}>
                      <Dropdown
                        className="btn"
                        value={item.language}
                        options={languages}
                        onChange={(e) => changeLanguage(e.value, item.id)}
                      />
                    </BookmakersTableCol>
                  </BookmakersTableRow>
                )
              })}
            </BookmakersTable>
          </ProfileCard>
        </ProfileMain>
      </ProfileLayout>
    </Main>
  )
})

export { Bookmakers }
