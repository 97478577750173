import { observer } from "mobx-react-lite"
import { useApp } from "../../../../hook/useApp"
import { useTranslation } from "react-i18next"
import { ForkNotFound } from "../../../notification/ForkNotFound"
import { Link } from "react-router-dom"
import {
  ForkTable,
  ForkTableThead,
  ForkTableBody,
  ForkTableFooter,
  ForkTableRow,
  ForkTableRowInvisible,
  ForkTableCell
} from "./ForkTable"
import {
  ActionList,
  ButtonCalculator,
  ButtonAllForks
} from "./actions/ForkActionElements"
import {
  CalculatorHeader,
  CalculatorBody,
  CalculatorFooter
} from "../calculator/Calculator"
import InputCardBetName from "../../../inputs/InputCardBetName"
import InputCardBetCoef from "../../../inputs/InputCardBetCoef"
import ButtonCardAction from "../../../buttons/ButtonCardAction"

import { Dropdown } from "primereact/dropdown"
import InputCalculator from "../../../inputs/InputCalculator"

import { IconAction } from "../../../ui/icons/Icons"
import { IconSport } from "../../../ui/icons/Icons"

import Progress from "../../../progress/Progress"

import {
  unixDateToDate,
  formatDate,
  formatTime,
  formatProfit,
  textBet,
  hasTimePassed,
  setUrlAllFork,
  hiddenCoef,
  formatCoef,
  getIconBySport,
  getSportNameBySportName
} from "../../../../helpers"
import React, { useState, useEffect } from "react"

export const ListForks = observer(() => {
  const { store } = useApp()
  const { t } = useTranslation()
  const isMobile = store.UIInterface.isMobile

  const forks = store.SportEvent.eventsArr

  return (
    <div className="feed-section__list">
      {store.SportEvent.loadingStatus ? (
        <ForkNotFound>
          <Progress visible={store.SportEvent.loadingStatus} />
        </ForkNotFound>
      ) : (
        ""
      )}
      {forks.length === 0 && !store.SportEvent.loadingStatus ? (
        <ForkNotFound />
      ) : (
        ""
      )}
      {forks.map((item) => {
        const {
          id,
          eventId,
          countBets,
          profitable,
          bets,
          eventDateTime,
          sportName,
          typeOdd,
          isNew,
          isRemoved
        } = item

        let sureBetDateTime = item.sureBetDateTime
        if (sureBetDateTime < 0) sureBetDateTime = 0

        const profit = formatProfit(profitable)

        const isFavorite = store.SportEvent.favoriteArr.includes(id)

        const forkData = { isNew, isRemoved, isFavorite, id }

        const headerData = {
          mobile: isMobile,
          profit,
          eventDateTime,
          sportName,
          id,
          isRemoved
        }
        const footerData = {
          mobile: isMobile,
          sureBetDateTime,
          id,
          countBets,
          eventId,
          coefType: bets[0].coefType,
          isRemoved
        }

        return (
          <Fork key={id} {...forkData}>
            {isRemoved && (
              <div className="event-card__removed">
                <span>{t("forks.fork-removed")}</span>
              </div>
            )}
            <ForkHeader>
              <ForkHeaderDesktop {...headerData} />
              <ForkHeaderMobile {...headerData} />
            </ForkHeader>
            <ForkBody>
              {bets.map((bet, index) => {
                let isPlus
                if (bet.newCoef && bet.newCoef !== bet.coef)
                  isPlus = bet.newCoef > bet.coef

                const itemBodyData = {
                  mobile: isMobile,
                  sportName,
                  isPlus,
                  typeOdd,
                  ...bet,
                  index,
                  id,
                  sureBetDateTime,
                  eventDateTime,
                  profit,
                  bets,
                  isRemoved
                }

                return (
                  <React.Fragment key={`${id}${index}`}>
                    <ForkBodyItemMobile {...itemBodyData} />
                    <ForkBodyItemDesktop {...itemBodyData} />
                  </React.Fragment>
                )
              })}
            </ForkBody>
            <ForkFooter>
              <ForkFooterMobile {...footerData} />
              <ForkFooterDesktop {...footerData} />
            </ForkFooter>
          </Fork>
        )
      })}
    </div>
  )
})

export const Fork = observer(
  ({ children, isFavorite, isNew, isRemoved, id }) => {
    const { store } = useApp()

    const listClasses = store.UIInterface.listForkStatuses
    const defaultSeconds = 30

    const [stateClass, setStateClass] = useState("")
    const [seconds, setSeconds] = useState(defaultSeconds)
    const [timer, setTimer] = useState(null)

    useEffect(() => {
      setStateClass("")
      setSeconds(defaultSeconds)

      if (isFavorite) setStateClass(listClasses["favorite"])

      if (isNew) {
        setStateClass(listClasses["new"])
        setTimer(setTimeout(() => setSeconds(seconds - 1), 1000))
      }

      if (isRemoved) {
        let deleteTime = store.Filter.currentFilter.deleteTimeBets || 3
        setTimeout(() => {
          store.SportEvent.deleteIsRemoved(id)
        }, deleteTime * 1000)
        setStateClass(listClasses["removed"])
      }

      return () => {
        clearTimeout(timer)
      }
    }, [isFavorite, isNew, isRemoved])

    useEffect(() => {
      if (seconds === 0) {
        clearTimeout(timer)

        if (isNew) {
          if (isFavorite) setStateClass(listClasses["favorite"])
          else setStateClass("")

          store.SportEvent.clearIsNew(id)
        }
      } else {
        if (isNew) setTimer(setTimeout(() => setSeconds(seconds - 1), 1000))
      }

      return () => {
        clearTimeout(timer)
      }
    }, [seconds])

    return (
      <article className={`event-card feed-section__list-card ${stateClass}`}>
        <ForkTable>{children}</ForkTable>
      </article>
    )
  }
)

export const ForkHeader = ({ children }) => (
  <ForkTableThead>{children}</ForkTableThead>
)

export const ForkHeaderMobile = observer(({ mobile, profit, id }) => {
  const { store } = useApp()
  const { t } = useTranslation()

  const isCalculator = store.SportEvent.idForShowCalculator === id

  if (!mobile) return ""

  return (
    <ForkTableRowInvisible visible={!isCalculator}>
      <ForkTableCell size={3}>
        <p className="event-card__title">{profit}%</p>
      </ForkTableCell>
      <ForkTableCell size={4}>
        <p className="event-card__title">{t("forks.bookmaker")}</p>
      </ForkTableCell>
      <ForkTableCell size={3}>
        <p className="event-card__title">{t("forks.bet")}</p>
      </ForkTableCell>
      <ForkTableCell size={1}>
        <p className="event-card__title">{t("forks.coef")}</p>
      </ForkTableCell>
      <ForkTableCell addClass="event-card__cell--flex-row-center" size={4}>
        <p className="event-card__title">{t("forks.event")}</p>
      </ForkTableCell>
    </ForkTableRowInvisible>
  )
})

export const ForkHeaderDesktop = observer(
  ({ mobile, eventDateTime, profit, sportName, id }) => {
    const { store } = useApp()
    const { t } = useTranslation()

    const isCalculator = store.SportEvent.idForShowCalculator === id

    const dateTime = unixDateToDate(eventDateTime)
    const time = formatTime(dateTime)
    const date = formatDate(dateTime)

    if (mobile) return ""

    return (
      <>
        <ForkTableCell size={1}>
          <p
            className="event-card__title tooltip-show"
            data-pr-tooltip={t("forks.profit")}
          >
            {profit}%
          </p>
        </ForkTableCell>
        <ForkTableCell size={2}>
          <div className="event-card__title-wrapper">
            <p
              className="event-card__title tooltip-show"
              data-pr-tooltip={t("forks.sport")}
            >
              {getSportNameBySportName(sportName)}
            </p>
            <p
              className="color-gray event-card__description tooltip-show"
              data-pr-tooltip={t("forks.time-start")}
            >
              {t("forks.starts-at")} {time}
            </p>
            <p
              className="color-gray event-card__description tooltip-show"
              data-pr-tooltip={t("forks.date-start")}
            >
              {date}
            </p>
          </div>
        </ForkTableCell>
        <ForkTableCell size={3}>
          <p className="event-card__title">{t("forks.bet")}</p>
        </ForkTableCell>
        <ForkTableCell size={4} visible={!isCalculator}>
          <p className="event-card__title">{t("forks.coefficient")}</p>
        </ForkTableCell>
        <ForkTableCell size={6} visible={isCalculator}>
          <CalculatorHeader />
        </ForkTableCell>
      </>
    )
  }
)

export const ForkBody = ({ children }) => (
  <ForkTableBody>{children}</ForkTableBody>
)

export const ForkBodyItemMobile = observer(
  ({
    mobile,
    sportName,
    index,
    bk,
    eventDateTime,
    coefType,
    coefParam,
    coefTypeParam,
    newCoef,
    coef,
    id,
    sureBetDateTime,
    profit,
    bets,
    typeOdd,
    isRemoved
  }) => {
    const { t } = useTranslation()
    const [timePassed, setTimePassed] = useState(hasTimePassed(sureBetDateTime))

    useEffect(() => {
      const timer = setInterval(() => {
        setTimePassed(hasTimePassed(sureBetDateTime))
        sureBetDateTime += 1
      }, 1000)

      return () => {
        clearInterval(timer)
      }
    }, [sureBetDateTime])

    const { store } = useApp()

    const isCalculator = store.SportEvent.idForShowCalculator === id
    const isFavorite = store.SportEvent.favoriteArr.includes(id)

    const sports = store.Filter.dataFilter.sports.data

    const isAuth = store.Auth.authUser

    function toggleCalculator() {
      store.SportEvent.setCalculator(id)
    }
    function changeFavorite() {
      store.SportEvent.changeFavorite(id)
    }

    function changeSumBet(val) {
      store.SportEvent.changeSumBetRow({ val, index })
    }
    function changeKefBet(val) {
      store.SportEvent.changeKefBetRow({ val, index })
    }
    function changeCurrencyFull(e) {
      store.SportEvent.changeCurrency(e.target.value)
    }
    function changeSumBetFull(val) {
      store.SportEvent.updateSumBet(val)
    }
    function resetCoefficients() {
      store.SportEvent.resetCoefficients()
    }
    function sendWarning() {
      store.UIInterface.changeStateModalWriteToUs({
        type: "title",
        value: t("notification.send-error")
      })
      store.UIInterface.changeStateModalWriteToUs({
        type: "type",
        value: t("footer.error")
      })
      store.UIInterface.changeStateModalWriteToUs({
        type: "visible",
        value: true
      })
    }

    const listCurrency = store.SportEvent.currenciesList
    const currentCurrency = store.SportEvent.currentCurrency

    const fullSumBet = store.SportEvent.fullSumBet
    const coefRow =
      store.SportEvent.calculatorValues.length > 0
        ? store.SportEvent.calculatorValues[index].coef
        : 0
    const sumBetRow =
      store.SportEvent.calculatorValues.length > 0
        ? store.SportEvent.calculatorValues[index].sumBet
        : 0
    const profitRow =
      store.SportEvent.calculatorValues.length > 0
        ? store.SportEvent.calculatorValues[index].profit
        : 0

    const dateTime = unixDateToDate(eventDateTime)
    const time = formatTime(dateTime)
    const date = formatDate(dateTime)

    const iconSportName = getIconBySport({ sportName, sports })

    if (!mobile) return ""

    return (
      <>
        <ForkTableRow visible={!isCalculator}>
          <ForkTableCell
            size={3}
            addClass="event-card__padding-left--15 position--relative"
          >
            <IconSport visible={index === 0} name={iconSportName} />
          </ForkTableCell>
          <ForkTableCell size={4}>
            <p className="event-card__title">{bk}</p>
          </ForkTableCell>
          <ForkTableCell size={3}>
            <p className="event-card__title">{textBet(coefType, coefParam)}</p>
          </ForkTableCell>
          <ForkTableCell size={1}>
            <p className="event-card__title">
              {formatCoef(newCoef ? newCoef : coef)}
            </p>
          </ForkTableCell>
          <ForkTableCell
            size={4}
            addClass="event-card__cell--flex-row-center position--relative"
          >
            {index === 0 ? (
              <button
                className="event-card__btn-invisible"
                onClick={toggleCalculator}
              >
                <IconAction name="info" />
              </button>
            ) : (
              ""
            )}
          </ForkTableCell>
        </ForkTableRow>
        <ForkTableRow visible={isCalculator && index === 0}>
          <ForkTableCell size={3}>
            <p className="event-card__title">{profit}%</p>
          </ForkTableCell>
          <ForkTableCell size={1}>
            <IconSport name={iconSportName} />
          </ForkTableCell>
          <ForkTableCell size={3}>
            <p className="event-card__title">{isRemoved ? "" : timePassed}</p>
          </ForkTableCell>
          <ForkTableCell size={2}>
            <ActionList>
              <button
                className="event-card__btn-invisible"
                onClick={changeFavorite}
              >
                <IconAction
                  name={isFavorite ? "favorite-active" : "favorite"}
                />
              </button>
              <button
                className="event-card__btn-invisible"
                onClick={sendWarning}
              >
                <IconAction name="alert-warning" />
              </button>
              {/* <IconAction name='remove' /> */}
            </ActionList>
          </ForkTableCell>
          <ForkTableCell size={1}>
            <button
              className="event-card__btn-invisible"
              onClick={toggleCalculator}
            >
              <IconAction name="close" />
            </button>
          </ForkTableCell>
        </ForkTableRow>
        <ForkTableRow visible={isCalculator && index === 0}>
          <ForkTableCell size={3}>
            <p className="event-card__title">{t("forks.bookmaker")}</p>
          </ForkTableCell>
          <ForkTableCell size={7}>
            <p className="event-card__title">{t("forks.bet")}</p>
          </ForkTableCell>
          <ForkTableCell size={7}>
            <p className="event-card__title">{t("forks.coef")}</p>
          </ForkTableCell>
          <ForkTableCell size={3}>
            <p className="event-card__title">{t("buttons.sum")}</p>
          </ForkTableCell>
          <ForkTableCell size={3}>
            <p className="event-card__title">{t("buttons.currency")}</p>
          </ForkTableCell>
          <ForkTableCell size={1}>
            <p className="event-card__title">{t("buttons.profit")}</p>
          </ForkTableCell>
        </ForkTableRow>
        <ForkTableRow visible={isCalculator}>
          <ForkTableCell size={3}>{bk}</ForkTableCell>
          <ForkTableCell size={7}>{textBet(coefType, coefParam)}</ForkTableCell>
          <ForkTableCell size={7}>
            <InputCalculator
              className="input-el event-card__coefficient-input"
              disabled={true}
              value={formatCoef(coefRow)}
              handler={changeKefBet}
            />
          </ForkTableCell>
          <ForkTableCell size={3}>
            <InputCalculator
              className="input-el event-card__coefficient-input"
              value={sumBetRow}
              handler={changeSumBet}
            />
          </ForkTableCell>
          <ForkTableCell size={3}>
            <Dropdown value={currentCurrency} options={listCurrency} />
          </ForkTableCell>
          <ForkTableCell size={1}>{profitRow}</ForkTableCell>
        </ForkTableRow>
        <ForkTableRowInvisible
          visible={isCalculator && index === bets.length - 1}
        >
          <ForkTableRow>
            <ForkTableCell size={3}></ForkTableCell>
            <ForkTableCell size={7}>
              {typeOdd}
              {coefTypeParam ? `. ${coefTypeParam}` : ""}
            </ForkTableCell>
            <ForkTableCell size={7}>
              <div className="width--max display--flex row--center">
                <button
                  className="event-card__btn-invisible active-animation"
                  onClick={resetCoefficients}
                >
                  <IconAction name="refresh-black" />
                </button>
              </div>
            </ForkTableCell>
            <ForkTableCell size={3}>
              <InputCalculator
                className="input-el event-card__coefficient-input"
                value={fullSumBet}
                handler={changeSumBetFull}
              />
            </ForkTableCell>
            <ForkTableCell size={3}>
              <Dropdown
                value={currentCurrency}
                options={listCurrency}
                onChange={changeCurrencyFull}
              />
            </ForkTableCell>
            <ForkTableCell size={1}></ForkTableCell>
          </ForkTableRow>
          <ForkTableRow>
            <ForkTableCell size={3}>{t("forks.bookmaker")}</ForkTableCell>
            <ForkTableCell size={4}>{t("forks.event")}</ForkTableCell>
            <ForkTableCell size={2} visible={isAuth && !coefType}>
              <Link to="/tariffs" className="normal-a">
                <ButtonCardAction theme="dark">
                  {t("forks.improve-tariff")}
                </ButtonCardAction>
              </Link>
            </ForkTableCell>
          </ForkTableRow>
          {bets.map((bet, index) => (
            <ForkTableRow key={`${id}-bk-${index}`}>
              <ForkTableCell size={3}>
                <p className="event-card__title">{bet.bk}</p>
              </ForkTableCell>
              <ForkTableCell size={8}>
                <button className="event-card__btn-invisible">
                  <IconAction name="copy" />
                </button>
                <a
                  href={bet.url}
                  target="_blank"
                  rel="noreferrer"
                  className="event-card__title event-card__url"
                >
                  {bet.eventName}
                </a>
              </ForkTableCell>
            </ForkTableRow>
          ))}
          <ForkTableRow>
            <ForkTableCell size={3}></ForkTableCell>
            <ForkTableCell size={2}>
              <p className="event-card__title">
                {t("forks.starts-at")} {time}
              </p>
            </ForkTableCell>
            <ForkTableCell size={2} addClass="event-card__cell--flex-row-end">
              <p className="event-card__title">{date}</p>
            </ForkTableCell>
          </ForkTableRow>
        </ForkTableRowInvisible>
      </>
    )
  }
)

export const ForkBodyItemDesktop = observer(
  ({
    mobile,
    bk,
    eventName,
    nameTournament,
    url,
    typeOdd,
    coefType,
    coefParam,
    coefTypeParam,
    isPlus,
    newCoef,
    coef,
    index,
    id
  }) => {
    const { store } = useApp()
    const { t } = useTranslation()

    const isCalculator = store.SportEvent.idForShowCalculator === id

    if (mobile) return ""

    return (
      <ForkTableRow>
        <ForkTableCell size={1}>
          <InputCardBetName>{bk}</InputCardBetName>
        </ForkTableCell>
        <ForkTableCell size={2}>
          <InputCardBetName url={url} copy={true}>
            {eventName}
          </InputCardBetName>
          <h3
            className="color-gray event-card__info tooltip-show"
            data-pr-tooltip={t("forks.tournament-name")}
            data-pr-position="bottom"
          >
            {nameTournament}
          </h3>
        </ForkTableCell>
        <ForkTableCell size={3}>
          <InputCardBetName>{textBet(coefType, coefParam)}</InputCardBetName>
          <div
            className="color-gray event-card__info tooltip-show"
            data-pr-tooltip={t("forks.bet-type")}
            data-pr-position="bottom"
          >
            {typeOdd}
            {coefTypeParam ? `. ${coefTypeParam}` : ""}
          </div>
        </ForkTableCell>
        <ForkTableCell size={4} visible={!isCalculator}>
          <InputCardBetCoef
            isPlus={isPlus}
            value={formatCoef(
              newCoef ? newCoef || hiddenCoef : coef || hiddenCoef
            )}
          />
        </ForkTableCell>
        <ForkTableCell size={6} visible={isCalculator}>
          <CalculatorBody index={index} isPlus={isPlus} />
        </ForkTableCell>
      </ForkTableRow>
    )
  }
)

export const ForkFooter = ({ children }) => (
  <ForkTableFooter>{children}</ForkTableFooter>
)

export const ForkFooterMobile = observer(
  ({
    mobile,
    id,
    sureBetDateTime,
    countBets,
    eventId,
    coefType,
    isRemoved
  }) => {
    const [timePassed, setTimePassed] = useState(
      hasTimePassed(sureBetDateTime, false)
    )

    useEffect(() => {
      const timer = setInterval(() => {
        setTimePassed(hasTimePassed(sureBetDateTime, false))
        sureBetDateTime += 1
      }, 1000)

      if (isRemoved) clearInterval(timer)

      return () => {
        clearInterval(timer)
      }
    }, [sureBetDateTime, isRemoved])

    const { store } = useApp()
    const { t } = useTranslation()

    function changeFavorite() {
      store.SportEvent.changeFavorite(id)
    }
    function toggleCalculator() {
      store.SportEvent.setCalculator(id)
    }
    function sendWarning() {
      store.UIInterface.changeStateModalWriteToUs({
        type: "title",
        value: t("notification.send-error")
      })
      store.UIInterface.changeStateModalWriteToUs({
        type: "type",
        value: t("footer.error")
      })
      store.UIInterface.changeStateModalWriteToUs({
        type: "visible",
        value: true
      })
    }

    const isCalculator = store.SportEvent.idForShowCalculator === id
    const isButtonForks = store.SportEvent.showButtonForks
    const isFavorite = store.SportEvent.favoriteArr.includes(id)
    const place = store.UIInterface.typeTimeSport

    const isAuth = store.Auth.authUser

    const urlForks = setUrlAllFork(place, eventId)

    if (!mobile || isCalculator) return ""

    return (
      <>
        <ForkTableRow>
          <ForkTableCell size={3}>
            <div className="event-card__time">{timePassed}</div>
          </ForkTableCell>
          <ForkTableCell size={4}>
            <ButtonAllForks
              mobile={mobile}
              visible={isButtonForks}
              countBets={countBets}
              url={urlForks}
            />
          </ForkTableCell>
          <ForkTableCell size={1} />
          <ForkTableCell size={2}>
            <ActionList align="center">
              <button
                className="event-card__btn-invisible"
                onClick={changeFavorite}
              >
                <IconAction
                  name={isFavorite ? "favorite-active" : "favorite"}
                />
              </button>
              <button
                className="event-card__btn-invisible"
                onClick={sendWarning}
              >
                <IconAction name="alert-warning" />
              </button>
              {/* <IconAction name='remove' /> */}
              <button
                className="event-card__btn-invisible"
                onClick={toggleCalculator}
              >
                <IconAction name="calculator" />
              </button>
            </ActionList>
          </ForkTableCell>
        </ForkTableRow>
        <ForkTableRow visible={isAuth && !coefType}>
          <ForkTableCell size={3} />
          <ForkTableCell size={2}>
            <Link to="/tariffs" className="normal-a">
              <ButtonCardAction theme="dark">
                {t("forks.improve-tariff")}
              </ButtonCardAction>
            </Link>
          </ForkTableCell>
        </ForkTableRow>
      </>
    )
  }
)
export const ForkFooterDesktop = observer(
  ({
    mobile,
    sureBetDateTime,
    id,
    countBets,
    eventId,
    coefType,
    isRemoved
  }) => {
    const [timePassed, setTimePassed] = useState(hasTimePassed(sureBetDateTime))

    const { store } = useApp()
    const { t } = useTranslation()

    useEffect(() => {
      const timer = setInterval(() => {
        setTimePassed(hasTimePassed(sureBetDateTime))
        sureBetDateTime += 1
      }, 1000)

      if (isRemoved) clearInterval(timer)

      return () => {
        clearInterval(timer)
      }
    }, [sureBetDateTime, isRemoved])

    function toggleCalculator() {
      store.SportEvent.setCalculator(id)
    }
    function changeFavorite() {
      store.SportEvent.changeFavorite(id)
    }

    function sendWarning() {
      store.UIInterface.changeStateModalWriteToUs({
        type: "title",
        value: t("notification.send-error")
      })
      store.UIInterface.changeStateModalWriteToUs({
        type: "type",
        value: t("footer.error")
      })
      store.UIInterface.changeStateModalWriteToUs({
        type: "visible",
        value: true
      })
    }

    const isProUser = store.UIInterface.isProUser
    const isButtonForks = store.SportEvent.showButtonForks
    const isFavorite = store.SportEvent.favoriteArr.includes(id)
    const place = store.UIInterface.typeTimeSport
    const urlForks = setUrlAllFork(place, eventId)
    const isCalculator = store.SportEvent.idForShowCalculator === id
    const isAuth = store.Auth.authUser

    if (mobile) return ""

    return (
      <>
        <ForkTableCell size={1}>
          <div className="event-card__title font-size--14">
            {t("forks.fork-activity")}
          </div>
          <div
            className="event-card__time tooltip-show"
            data-pr-tooltip={t("forks.life-time")}
          >
            {timePassed}
          </div>
        </ForkTableCell>
        <ForkTableCell size={3} visible={isProUser}>
          <ActionList>
            <ButtonCalculator
              isDisabled={isRemoved}
              active={isCalculator}
              handler={toggleCalculator}
            ></ButtonCalculator>
            <ButtonAllForks
              visible={isButtonForks}
              countBets={countBets}
              url={urlForks}
            />
          </ActionList>
        </ForkTableCell>
        <ForkTableCell size={3} visible={isProUser} />
        <ForkTableCell size={3} visible={isProUser}>
          <ActionList align="center">
            <button
              className="event-card__btn-invisible"
              onClick={changeFavorite}
            >
              <span
                className="tooltip-show"
                data-pr-tooltip={t("forks.add-favourite")}
                data-pr-position="left"
              >
                <IconAction
                  name={isFavorite ? "favorite-active" : "favorite"}
                />
              </span>
            </button>
            <button className="event-card__btn-invisible" onClick={sendWarning}>
              <span
                className="tooltip-show"
                data-pr-tooltip={t("notification.send-error")}
                data-pr-position="bottom"
              >
                <IconAction name="alert-warning" />
              </span>
            </button>
            {/* <span className="tooltip-show" data-pr-tooltip={t("forks.hide-list")} data-pr-position="right">
              <IconAction name='remove' />
            </span> */}
          </ActionList>
        </ForkTableCell>
        <ForkTableCell size={5} visible={!isProUser}>
          <ActionList>
            <ButtonCalculator
              isDisabled={isRemoved}
              active={isCalculator}
              handler={toggleCalculator}
            ></ButtonCalculator>
            <ButtonAllForks
              visible={isButtonForks}
              countBets={countBets}
              url={urlForks}
            />
            <ButtonCardAction
              isDisabled={isRemoved}
              theme={isFavorite ? "favorite" : null}
              handler={changeFavorite}
            >
              {t("buttons.favourite")}
            </ButtonCardAction>
            {/* <ButtonCardAction isDisabled={isRemoved}>
              {t("buttons.hide")}
            </ButtonCardAction> */}
            <ButtonCardAction isDisabled={isRemoved} handler={sendWarning}>
              {t("notification.send-error")}
            </ButtonCardAction>
            {isAuth && !coefType ? (
              <Link to="/tariffs" className="normal-a">
                <ButtonCardAction theme="dark">
                  {t("forks.improve-tariff")}
                </ButtonCardAction>
              </Link>
            ) : (
              ""
            )}
          </ActionList>
        </ForkTableCell>
        <ForkTableCell size={6} visible={isCalculator}>
          <CalculatorFooter />
        </ForkTableCell>
      </>
    )
  }
)
