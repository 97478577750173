import { useTranslation } from "react-i18next"

const BookmakersTableCheckbox = () => {
  const { t } = useTranslation()

  return (
    <label className="checkbox-label bookmaker-table__domain">
      <input
        className="checkbox-label__control"
        type="checkbox"
        name="domain"
        value={t("account.enter-domain")}
      />
      <span className="checkbox-label__inner">
        <span className="checkbox-label__inner-icon"></span>
        <span className="checkbox-label__inner-text">
          {t("account.enter-domain")}
        </span>
      </span>
    </label>
  )
}

export default BookmakersTableCheckbox
