import { useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useApp } from "../../../../hook/useApp"
import { useTranslation } from "react-i18next"

const AsideHeader = observer(() => {
  const { store } = useApp()
  const { t, i18n } = useTranslation()

  const [language, setLanguage] = useState(null)

  useEffect(() => {
    let language = localStorage.getItem("language")
    setLanguage(language || "ru")
  }, [])

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
    localStorage.setItem("language", lng)
    setLanguage(lng)
  }

  return (
    <div className="profile-aside__header">
      <div className="profile-aside__header-title">{t("account.profile")}</div>
      <p className="profile-aside__header-login">{store.Auth.authUser.sub}</p>
      <div>
        <button
          type="button"
          className={`btn btn--size-sm btn--theme-${language === "ru" ? "dark" : "gray"} margin-right--10`}
          onClick={() => changeLanguage("ru")}
        >
          ru
        </button>
        <button
          type="button"
          className={`btn btn--size-sm btn--theme-${language === "en" ? "dark" : "gray"}`}
          onClick={() => changeLanguage("en")}
        >
          en
        </button>
      </div>
    </div>
  )
})

export default AsideHeader
