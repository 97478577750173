import { observer } from "mobx-react-lite"
import { useApp } from "../../hook/useApp"
import { useTranslation } from "react-i18next"

const ButtonWriteToUs = observer(({ children }) => {
  const { store } = useApp()
  const { t } = useTranslation()

  function handlerClick() {
    store.UIInterface.changeStateModalWriteToUs({
      type: "title",
      value: t("buttons.contact-us")
    })
    store.UIInterface.changeStateModalWriteToUs({
      type: "type",
      value: t("buttons.appeal")
    })
    store.UIInterface.changeStateModalWriteToUs({
      type: "visible",
      value: true
    })
    store.UIInterface.changeStateMenu()
  }

  return (
    <button
      className="lg-visible btn btn--size-md btn--theme-white-accent header__action-write"
      onClick={handlerClick}
    >
      {children}
    </button>
  )
})

export default ButtonWriteToUs
