import { useApp } from "../../../../hook/useApp"
import { observer } from "mobx-react-lite"
import ButtonFilter from "../../../buttons/ButtonFilter"

import { isLiveForTopEvents } from "../../../../helpers"

import { IconSport, IconAction } from "../../../ui/icons/Icons"

import React, { useRef } from "react"
import { OverlayPanel } from "primereact/overlaypanel"
import { Chip } from "primereact/chip"
import { SelectButton } from "primereact/selectbutton"
import { Dropdown } from "primereact/dropdown"
import { InputDecimal } from "../../../inputs/InputDecimal"
import { useTranslation } from "react-i18next"
import { places } from "../../../../constants"

export const FilterProfit = observer(({ visible }) => {
  const { store } = useApp()
  const { t } = useTranslation()
  const overPanel = useRef(null)
  const profit = store.Filter.dataFilter.profit.value

  let timer

  function handlerClick(e) {
    overPanel.current.toggle(e)
  }
  function changeProfit(e) {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      const profit = e.target.value
      store.Filter.changeProfit(profit)

      clearTimeout(timer)
    }, 300)
  }

  if (!visible) return ""

  return (
    <>
      <ButtonFilter isActive={profit > 0} handler={handlerClick}>
        {t("filter.profit")}
        {`${profit > 0 ? ` > ${profit} %` : ""}`}
      </ButtonFilter>
      <OverlayPanel ref={overPanel}>
        <div className="popup">
          <div className="popup__title">{t("filter.enter-profit")}</div>
          <div className="popup__content">
            <span>{">="}</span>
            <InputDecimal
              type="text"
              onChange={changeProfit}
              defaultValue={profit > 0 ? profit : ""}
            />
            <span>%</span>
          </div>
        </div>
      </OverlayPanel>
    </>
  )
})

export const FilterTypeTimeSport = observer(({ visible = true }) => {
  const { store } = useApp()
  // const { t } = useTranslation()

  const dataFilter = store.Filter.dataFilter

  const place = dataFilter.place.value.label
  const sportName = dataFilter.sports.value
  const arrPlace = dataFilter.place.data

  // const toast = store.UIInterface.toast

  function chageTypeTimeSport(val) {
    if (!val) return

    if (isLiveForTopEvents({ isLive: val === "LIVE", sportName })) {
      // toast.current.show({
      //   severity: 'warning',
      //   summary: t("notification.warning"),
      //   detail: t("notification.warning-detail"),
      //   life: 3000
      // })
      return
    }

    store.Filter.changePlace(val)
    store.SportEvent.changeTypeBets(val === places[0])
  }

  if (!visible) return ""

  return (
    <SelectButton
      value={place}
      options={arrPlace}
      onChange={(e) => chageTypeTimeSport(e.value)}
      optionDisabled="constant"
      className="prematch"
    />
  )
})

export const FilterRefreshEvents = observer(({ visible, mobile }) => {
  const { store } = useApp()
  const { t } = useTranslation()

  function updateForks() {
    store.SportEvent.getEventsFromServer()
  }

  if (!visible) return ""

  return (
    <span
      className="tooltip-show"
      data-pr-tooltip={t("buttons.update-events")}
      data-pr-position="top"
    >
      <ButtonFilter handler={updateForks}>
        {mobile ? (
          <IconAction name="refresh" className="icon icon_size-20px" />
        ) : (
          t("buttons.update")
        )}
      </ButtonFilter>
    </span>
  )
})

export const FilterAutoRefreshEvents = observer(({ visible, mobile }) => {
  const { store } = useApp()
  const { t } = useTranslation()

  const autoRefresh = store.UIInterface.autoRefresh
  function changeStateAutoRefresh() {
    store.UIInterface.changeStateAutoRefresh()
  }

  const textActive = autoRefresh
    ? t("filter.auto-refresh-on")
    : t("filter.auto-refresh-off")

  const text = mobile
    ? `${t("filter.auto")}${textActive}`
    : `${t("filter.auto-update")}${textActive}`

  if (!visible) return ""
  return (
    <span
      className="tooltip-show"
      data-pr-tooltip={t("filter.auto-update-events")}
      data-pr-position="top"
    >
      <ButtonFilter isActive={autoRefresh} handler={changeStateAutoRefresh}>
        {mobile ? (
          <>
            <span>{text}</span>
            <IconAction
              name="refresh"
              className="icon icon_size-20px icon_margin-left-10px"
            />
          </>
        ) : (
          text
        )}
      </ButtonFilter>
    </span>
  )
})

export const FilterFilter = observer(({ visible }) => {
  const { store } = useApp()
  const { t } = useTranslation()

  const dataFilter = store.Filter.dataFilter
  const filter = store.Filter.currentFilter

  const bks = dataFilter.bks.value
  const isActiveBks = bks.find((item) => !item.checked)

  const profit = dataFilter.profit.value

  const filterSports = filter?.sportName?.length > 1

  function handlerClick(e) {
    store.Filter.toggleModalWindow()

    document.querySelector("body, html").style.overflow = "hidden"
  }

  if (!visible) return ""

  return (
    <ButtonFilter
      isActive={filter?.isFraction || isActiveBks || profit > 0 || filterSports}
      handler={handlerClick}
    >
      {t("filter.filter")}
    </ButtonFilter>
  )
})

export const FilterTime = observer(({ visible }) => {
  const { store } = useApp()
  const { t } = useTranslation()

  const timeEvent = store.Filter.currentFilter.timeEvent
  const dataFilter = store.Filter.dataFilter
  const arrTimeEvent = dataFilter.timeEvent.data
  const label = dataFilter.timeEvent.value.label

  const overPanel = useRef(null)
  function handlerClick(e) {
    overPanel.current.toggle(e)
  }
  async function handlerChange(value) {
    await store.Filter.changeTimeEvent(value)
  }

  if (!visible) return ""

  return (
    <span
      className="tooltip-show"
      data-pr-tooltip={t("filter.upcoming-events")}
      data-pr-position="top"
    >
      <ButtonFilter isActive={timeEvent !== "ANY_TIME"} handler={handlerClick}>
        {`${t("filter.time")}${timeEvent !== "ANY_TIME" ? ` ${label}` : ""}`}
      </ButtonFilter>
      <OverlayPanel ref={overPanel}>
        <div className="popup">
          <div className="popup__title">{t("filter.time-before")}</div>
          <div className="popup__content">
            <Dropdown
              className="p-dropdown--theme-white-accent"
              value={timeEvent}
              options={arrTimeEvent}
              onChange={(e) => handlerChange(e.value)}
            />
          </div>
        </div>
      </OverlayPanel>
    </span>
  )
})

export const FilterSports = observer(({ mobile }) => {
  const { store } = useApp()
  const { t } = useTranslation()

  const dataFilter = store.Filter.dataFilter

  const arrSport = dataFilter.sports.data
  const currentSport = dataFilter.sports.value
  const isLive = !store.SportEvent.isPrematch

  // const toast = store.UIInterface.toast

  async function changeActive(value) {
    if (isLiveForTopEvents({ isLive, sportName: value })) {
      // toast.current.show({
      //   severity: 'warning',
      //   summary: t("notification.warning"),
      //   detail: t("notification.warning-detail"),
      //   life: 3000
      // });
      return
    }

    if (value === currentSport) return

    store.Filter.changeActiveSport(value)
  }

  return (
    <>
      {arrSport.map((item) => {
        let className = [
          isLive && item.label === t("constants.top") ? "disabled" : "",
          item.filter === currentSport ? "active " : ""
        ]
          .join(" ")
          .trim()

        return (
          <Chip
            onClick={() => changeActive(item.label)}
            key={item.label}
            className={className}
            template={
              <>
                {<IconSport name={item.type} />}
                {mobile ? "" : <span>{item.label}</span>}
              </>
            }
          />
        )
      })}
    </>
  )
})
