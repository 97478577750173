import ButtonCardAction from "../../../../buttons/ButtonCardAction"
import { IconAction } from "../../../../ui/icons/Icons"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

export const ActionList = ({ children, align }) => {
  return (
    <div
      className={`event-card__btn-list${align ? ` event-card__btn-list--${align}` : ""}`}
    >
      {children}
    </div>
  )
}

export const ButtonCalculator = ({ active = false, handler, isDisabled }) => {
  const { t } = useTranslation()

  return (
    <button
      className={`js-event-btn-calculator btn btn--size-md btn--theme-${isDisabled ? "disabled" : "simple"} event-card__btn-list-item`}
      type="button"
      onClick={handler}
    >
      <span className="btn__icon-wrapper btn__icon-wrapper--ltr">
        {active ? (
          <IconAction name="close" className="icon btn__icon" />
        ) : (
          <IconAction name="calculator" className="icon btn__icon" />
        )}
      </span>
      {t("buttons.calculator")}
    </button>
  )
}

export const ButtonAllForks = ({ visible, mobile = false, countBets, url }) => {
  const { t } = useTranslation()

  if (!visible) return ""

  if (countBets < 2) {
    if (!mobile)
      return (
        <ButtonCardAction isDisabled={true}>
          {t("buttons.all-forks")}
        </ButtonCardAction>
      )
    return ""
  }

  const text =
    countBets < 2
      ? t("buttons.all-forks")
      : `${t("buttons.all-forks")} (${countBets})`

  return (
    <Link to={url} className="event-card__href normal-a">
      {mobile ? (
        text
      ) : (
        <ButtonCardAction theme="selection">{text}</ButtonCardAction>
      )}
    </Link>
  )
}
