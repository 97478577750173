import { useEffect, useState } from "react"
import Main from "../../../components/layout/main/Main"
import { TaxRequestsRu } from "./TaxRequests/TaxRequestsRu"
import { TaxRequestsEn } from "./TaxRequests/TaxRequestsEn"

const Markup = (language) => {
  switch (language) {
    case "ru":
      return <TaxRequestsRu />
    case "en":
      return <TaxRequestsEn />
  }
}

export const TaxRequests = () => {
  const [language, setLanguage] = useState(null)

  useEffect(() => {
    let language = localStorage.getItem("language")
    setLanguage(language || "ru")
  }, [])

  return <Main>{Markup(language)}</Main>
}
