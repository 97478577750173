import { useTranslation } from "react-i18next"

const ProfileCardFormPromocode = () => {
  const { t } = useTranslation()

  return (
    <form className="profile-settings-card__promocode">
      <input
        className="input-el input-el--size-md input-el--theme-white profile-settings-card__promocode-input"
        type="text"
        name="promocode"
        placeholder={t("sign-up.password-repeat")}
      />
      <button
        className="btn btn--theme-dark btn--size-st profile-settings-card__promocode-btn"
        type="submit"
      >
        {t("sign-up.password-repeat")}
      </button>
    </form>
  )
}

export default ProfileCardFormPromocode
