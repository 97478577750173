import { Routes, Route, Navigate } from "react-router-dom"

import { MainLayout } from "./layouts/MainLayout"

import { Home } from "./views/Home"
import { Account } from "./views/account/Account"
import { Bookmakers } from "./views/account/Bookmakers"
import { Settings } from "./views/account/Settings"
import { AccountPayments } from "./views/account/AccountPayments"
import { Event } from "./views/Event"
import { AccountActivate } from "./views/account/AccountActivate"

import { Terms } from "./views/public/Terms"
import { Privacy } from "./views/public/Privacy"

import { NotFound } from "./views/NotFound"

import { AuthProvider } from "./hoc/AuthProvider"

// Публичные страницы / Главная
import { Forks, Feature, Promotion, Tariffs } from "./views/public/main"
// Публичные страницы / Информация
import { About, Payments, Refund, Rules } from "./views/public/info"
// Публичные страницы / С чего начать
import {
  HowPlaceBet,
  HowWithdrawMoney,
  HowRecharge,
  Identification,
  StartWinning
} from "./views/public/forstart"
// Публичные страницы / Поддержка
import { Faq, LegalRequests, TaxRequests } from "./views/public/support"

import { observer } from "mobx-react-lite"
import { useApp } from "./hook/useApp"

const App = observer(() => {
  const { store } = useApp()
  const authUser = store.Auth.authUser

  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path="/">
          <Route
            path="/signin"
            element={authUser ? <Navigate to="/" /> : <Home />}
          />
          <Route
            path="/signup"
            element={authUser ? <Navigate to="/" /> : <Home />}
          />
          <Route
            path="/forgot-password"
            element={authUser ? <Navigate to="/" /> : <Home />}
          />
          <Route
            path="/change-password/:id"
            element={authUser ? <Navigate to="/" /> : <Home />}
          />
        </Route>
        <Route path="/profile" element={<AuthProvider />}>
          <Route index element={<Account />} />
          <Route path="account" element={<Account />} />
          <Route path="settings" element={<Settings />} />
          <Route path="payments" element={<AccountPayments />} />
          <Route path="bookmakers" element={<Bookmakers />} />
        </Route>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="account/activate/:id" element={<AccountActivate />} />
          <Route path="line/:id" element={<Event />} />
          <Route path="live/:id" element={<Event />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />

          <Route path="forks" element={<Forks />} />
          <Route path="feature" element={<Feature />} />
          <Route path="tariffs" element={<Tariffs />} />
          <Route path="promotion" element={<Promotion />} />

          <Route path="about" element={<About />} />
          <Route path="rules" element={<Rules />} />
          <Route path="payments" element={<Payments />} />
          <Route path="refund" element={<Refund />} />

          <Route path="start-winning" element={<StartWinning />} />
          <Route path="how-place-bet" element={<HowPlaceBet />} />
          <Route path="how-recharge" element={<HowRecharge />} />
          <Route path="how-withdraw-money" element={<HowWithdrawMoney />} />
          <Route path="identification" element={<Identification />} />

          <Route path="legal-requests" element={<LegalRequests />} />
          <Route path="tax-requests" element={<TaxRequests />} />
          <Route path="faq" element={<Faq />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
})

export default App
