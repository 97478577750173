import { useEffect, useState } from "react"
import Main from "../../components/layout/main/Main"
import { PrivacyRu } from "./Privacy/PrivacyRu"
import { PrivacyEn } from "./Privacy/PrivacyEn"

const Markup = (language) => {
  switch (language) {
    case "ru":
      return <PrivacyRu />
    case "en":
      return <PrivacyEn />
  }
}

export const Privacy = () => {
  const [language, setLanguage] = useState(null)

  useEffect(() => {
    let language = localStorage.getItem("language")
    setLanguage(language || "ru")
  }, [])

  return <Main>{Markup(language)}</Main>
}
